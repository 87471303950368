export default {
  es: {
    //paymentExpired
    text_payment_expired_title: "Tu suscripción esta vencida",
    text_payment_expired_description:
      "Algunas funciones están bloqueadas, renueva tu suscripción para acceder a todo el contenido y servicios.",
    text_payment_expired_btn: "Mi Suscripción",
    text_payment_limitProducts: "Has superado el limite de productos",
    //menu
    text_nosesion_title: "Inicia sesión nuevamente",
    text_nosesion_subtitle:
      "Tu sesión ha terminado por inactividad. Debes volver a iniciar sesión para acceder al panel administrador de tu tienda.",
    text_nosesion_iniciar: "Iniciar sesión",
    text_inicio: "Inicio",
    text_inventario: "Inventario",
    text_ventas: "Ventas",
    text_tienda_online: "Tienda online",
    text_analytics: "Estadísticas",
    text_estadisticas: "📊 Estadísticas",
    text_analyticsProduct: "📦 Productos",
    text_whatsapp: "WhatsApp",
    text_academia: "Academia",
    text_LinkKomercia: "Link Komercia",
    text_empresa: "Empresa",
    text_regalo: "Premios",
    text_integraciones: "Integraciones",
    text_mi_perfil: "Mi Perfil",
    text_ayuda: "Ayuda",
    text_salir: "Salir",
    text_inventario2: "📦 Inventario",
    text_categorias: "Categorías",
    text_tags: "Etiquetas",
    text_papelera: "Papelera",
    text_guardaCambios: "Guardar cambios",
    text_guardadoCorrectamente: "Guardado correctamente",
    //HelpDesk
    text_helpDesk: "HelpDesk",
    text_helpDesk2: "📎 HelpDesk",
    text_helpDesk_listado: "Artículos",
    text_helpDesk_editor: "Editor Template",
    text_helpDesk_congfiguracion: "Configuración",
    //Inicio
    text_inicio_visitar: "Visitas",
    text_inicio_totales: "totales",
    text_inicio_productos: "Productos",
    text_inicio_listDropshipping: "Productos dropshipping",
    text_inicio_publicados: "publicados",
    text_inicio_ventas: "Ventas",
    text_inicio_generadas: "generadas",
    text_inicio_valor: "Valor",
    text_inicio_total: "total",
    text_inicio_ingresos: "Ingresos",
    text_inicio_nuevaIntegracion: "Nueva Integración!",
    text_inicio_facebook_info:
      "Asiste a los clientes que exploran tu sitio web con el plugin de chat de Facebook gratuito para conseguir ventas.",
    text_inicio_capacitacion: "Aprende más sobre ",
    text_inicio_capacitacion2:
      "y sus funcionalidades, saca el máximo beneficio de tu plan y mejora tu tienda virtual con nosotros.",
    text_inicio_conectar: "Conectar",
    text_inicio_participar: "Quiero participar",
    text_inicio_cursoRecomendados: "Cursos recomendados",
    text_inicio_ver_cursoRecomendados: "Ver todos los cursos",
    text_inicio_videosRecomendados: "Videos recomendados",
    text_inicio_ver_videosRecomendados: "Ver todos los videos",
    text_inicio_video_info1:
      "Gestión de envíos de productos - Alianza Envioclick",
    text_inicio_video_info2: "Transformación digital en empresarios Gran San",
    text_inicio_video_info3: "Catálogo de WhatsApp",
    text_inicio_video_info4: "Komercia Chile",
    text_inicio_video_info5:
      "Aprende a crear tu tienda online desde cero online",
    text_inicio_video_info6:
      "Aterrizando el marketing digital con Luis Betancourt",
    text_inicio_video_info7: "Curso básico de fotografía de producto",
    text_inicio_video_info8: "Curso de Facebook e Instagram Ads",
    text_inicio_blogRecomendados: "Blogs",
    text_inicio_ver_blogRecomendados: "Ver todos los artículos",
    text_inicio_blog_info1:
      "Guia Completa para activar Instagram Shopping con tu tienda online",
    text_inicio_blog_info2:
      "¿Cuáles son los Tamaños de los Banners de las tiendas online creadas en Komercia.co?",
    text_inicio_blog_info3: "Integra el Pixel de Facebook en tu tienda online",
    text_inicio_blog_info4: "Integración Facebook Messenger",
    text_inicio_hola: "Hola, ",
    text_inicio_tuTiendaOnline: "Tu tienda online:",
    text_inicio_tuTiendaOnlineEstaCerrada:
      "Tu tienda esta cerrada y no puede recibir ventas",
    text_inicio_abrirTienda: "Abrir Tienda",
    //Inventario
    text_inventario_1: "Integración Facebook Messenger",
    text_inventario_duplicar: "Duplicar",
    text_inventario_ver: " Ver",
    text_inventario_aprendeMas: "Aprende mas sobre ",
    //categorias
    text_inventario_probar: "Probar",
    text_inventario_editar: "Editar",
    text_inventario_elimnar: "Eliminar",
    text_inventario_previsualizar: "Previsualizar",
    text_inventario_restablecar: "Restablecer",
    text_inventario_nombre: "Nombre",
    text_inventario_numeroProductos: "Número de productos",
    text_inventario_copiarUrl: "Copiar URL",
    text_inventario_escribeCategoria: "Escribe la categoría",
    text_inventario_urlCategoriaCopiada: "url de categoría copiada!",
    text_inventario_copiar: "Copiar",
    text_inventario_escribeSubCategoria: "Escribe la subcategoría",
    text_inventario_noHayCategorias: "No hay categorías",
    text_inventario_agregarCategoriaLista: "Agrega una categoría a la lista",
    text_inventario_NuevaCategoria: "Nueva categoría",
    text_inventario_subNuevaCategoria: "Nueva subcategoría",
    text_inventario_editarCategoria: "Editar categoría",
    text_inventario_editarSubCategoria: "Editar subcategoría",
    text_inventario_agregarSubCategoriaLista:
      "Agrega una nueva subcategoría a la lista",
    text_inventario_seleccionaCategoria: "Selecciona la categoría",
    text_inventario_seleccionaSubCategoria: "Selecciona la subcategoría",
    text_inventario_aprendeSobreCategorias: "Aprende mas sobre categorías",
    //papelera
    text_inventario_papelera: "Papelera",
    text_inventario_tienes: "Tienes",
    text_inventario_prodcuctosQueNoEstanPublicados:
      "productos que no están publicados en tu tienda",
    text_inventario_yaSuperaste: "Ya superaste los",
    text_inventario_actualizaTuPLan: "productos, actualiza tu plan",
    text_inventario_eliminarSeleccionados: "Eliminar seleccionados",
    text_inventario_publicarSeleccionados: "Publicar seleccionados",
    text_inventario_publicar: "Publicar",
    text_inventario_id: "Id",
    text_inventario_foto: "Foto",
    //productos
    text_inventario_informacion: "Información",
    text_inventario_photos: "Fotos",
    text_inventario_precios: "Precios",
    text_inventario_filtros: "Organizar",
    text_inventario_dropshipping: "Dropshipping",
    text_inventario_vendedores: "Vendedores",
    text_inventario_cancelar: "Cancelar",
    text_inventario_guardar: "Guardar",
    text_inventario_cargando: "Cargando...",
    text_inventario_fotoProducto: "Foto del producto",
    text_inventario_subirOtraImagen: "Subir otra imagen",
    text_inventario_videoProducto: "Video del producto (opcional)",
    text_inventario_URLvideoYoutube: "URL video de YouTube",
    text_inventario_lisdatoProducto: "Listado de productos",
    text_inventario_productoN: "Producto N°",
    text_inventario_organizar: "Organizar",
    text_inventario_textCrearOrganizaProductos:
      "Crea categorías y organiza los productos de tu tienda para que sea mas fácil a tu clientes encontrarlos.",
    text_inventario_filtrar: "Filtrar",
    text_inventario_textFiltrar:
      "Crea etiquetas para filtrar los productos de tu tienda para que sea mas fácil a tu clientes encontrarlos.",
    text_inventario_subCategorias: "Subcategorias",
    text_inventario_sinCategorias: "Sin categoría",
    text_inventario_sinSubCategorias: "Sin subcategoría",
    text_inventario_favoritos: "Favoritos",
    text_inventario_peso: "Peso",
    text_inventario_largo: "Largo",
    text_inventario_ancho: "Ancho",
    text_inventario_alto: "Alto",
    text_inventario_garantia: "Garantía",
    text_inventario_garantiaProducto: "Garantía del producto",
    text_inventario_marca: "Marca",
    text_inventario_palabrasClave: "Palabras clave",
    text_inventario_añadirFavoritos: "⭐ Añadir a favoritos",
    text_inventario_añadidoFavoritos: "⭐ Añadido a favoritos",
    text_inventario_envioGratis: "Envío Gratis",
    text_inventario_recomendado: "Recomendado",
    text_inventario_productosConEnviosGratis:
      "los productos con envió gratis aumentan sus posibilidades de venta",
    text_inventario_no: "No",
    text_inventario_si: "Si",
    text_inventario_descriptionCorta: "Descripción corta (140 caracteres)",
    text_inventario_descriptionCortaMSG: "Descripción corta",
    text_inventario_requerido: "Requerido",
    text_inventario_description: "Descripción",
    text_inventario_opcional: "Opcional",
    text_inventario_priopridadAtienda: "Prioridad de aparición en la tienda",
    text_inventario_priopridadAtiendaMSG:
      "Los productos con alta prioridad (10) aparecerán en las primeras posiciones en tu tienda.",
    text_inventario_deseaAplicarDescuento:
      "¿Desea aplicar descuento simulado al producto?",
    text_inventario_deseaAplicarDescuentoreal:
      "¿Desea aplicar descuento real al producto?",
    text_inventario_ValorPorcentaje: "Valor del porcentaje",
    text_inventario_nota: "Nota: ",
    text_inventario_notaMsg:
      "El valor del porcentaje no debe llegar al 100%, máximo 99%",
    text_inventario_escribirMensajeProducto:
      "¿Escribir mensaje junto a este producto?",
    text_inventario_escribirMensajeProductoMSG:
      "¿Es obligatorio llenar el mensaje por parte del comprador?",
    text_inventario_etiquetaInstrucciones:
      "Etiqueta /Instrucciones para el mensaje",
    text_inventario_productoVariantes: "Producto sin variantes",
    text_inventario_referencia: "Referencia (opcional)",
    text_inventario_SKU: "SKU",
    text_inventario_infoVariantes:
      "Las variantes son características adicionales del producto.",
    text_inventario_porEjemplo: "Por ejemplo:",
    text_inventario_infoVariantes2:
      "talla, material o color, que te permiten organizar tu inventario",
    text_inventario_crearVariantes: "Crear variantes",
    text_inventario_infoVariantes3:
      "Agregue variantes si este producto viene en múltiples versiones, como diferentes tamaños o colores.",
    text_inventario_nombreOpcion: "Nombre de la opción",
    text_inventario_ValoresOpcion: "Valores de la opción",
    text_inventario_escribeOpcion: "Escribe la opción",
    text_inventario_cambiarNombre:
      "Cambiar el nombre. Eliminar espacios al inicio y al final",
    text_inventario_agregar: "Agregar",
    text_inventario_agregarVariantes: "Agregar variante",
    text_inventario_modificarVariantes:
      "Modificar las variantes que se crearán:",
    text_inventario_variantes: "Variante",
    text_inventario_referencia2: "Referencia",
    text_inventario_mejoraTuPlan: "Mejora tu plan ahora",
    text_inventario_generarDescription: "Generar descripción",
    text_inventario_generandoDescription: "Generando descripción",
    text_inventario_inteligenciaArtificial: "Inteligencia artificial",
    text_inventario_inteligenciaArtificial_description:
      "Para generar una descripción del producto mediante inteligencia artificial debe ingresar unas palabras claves.",
    text_inventario_palabrasClaves: "Palabras claves",
    text_inventario_seleccionarIdioma: "Seleccionar idioma",
    //Ventas
    text_ventas_buscarProducto: "Buscar Producto",
    text_ventas_todos: "Todos",
    text_ventas_exportar: "Exportar",
    text_ventas_importar: "Importar",
    text_ventas_ver_productos: "Ver productos",
    text_ventas_editar_productos: "Editar productos",
    text_ventas_agotados: "Agotados",
    text_ventas_nuevo_producto: "Nuevo producto",
    text_ventas_nombre_producto: "Nombre del producto",
    text_ventas_combinacion: "Combinación",
    text_ventas_unidades: "Unidades",
    text_ventas_precio: "Precio",
    text_ventas_accciones: "Acciones",
    text_ventas_agotado: "AGOTADO",
    text_ventas_producto_variantes: "Producto con variantes",
    text_ventas_variantes_desactivas: "Variantes desactivadas",
    text_ventas_state_sales: "Estado venta",
    text_ventas_state_shipment: "Estado envió",
    //menu
    text_ventas_title: "💰 Ventas",
    text_ventas_listado: "Listado Ventas",
    text_ventas_listado_hoko: "Listado Hoko",
    text_ventas_ventaRapida: "Venta rápida",
    text_ventas_clientes: "Clientes",
    text_ventas_clientes2: "👤 Clientes",
    //tablet
    text_ventas_misVentas: "Mis ventas",
    text_ventas_misVentas_hoko: "Mis ventas Hoko",
    text_ventas_filtrarNOrden: "Filtrar por No. de orden",
    text_ventas_BUscarNOrden: "Buscar No. de orden",
    text_ventas_filtrarEstado: "Filtrar por estado",
    text_ventas_filtrarMethodPay: "Filtrar por método de pago",
    text_ventas_seleccionaEstado: "Selecciona un estado",
    text_ventas_filtrarFecha: "Filtrar por fecha",
    text_ventas_seleccionaFecha: "Selecciona una fecha",
    text_ventas_filtrarCliente: "Filtrar por cliente",
    text_ventas_filtrarID: "Filtrar por identificación",
    text_ventas_seleccionaCliente: "Selecciona un cliente",
    text_ventas_exportarVentas: "Exportar ventas",
    text_ventas_visibilidad: "Visible",
    text_ventas_publico: "Publico",
    text_ventas_privado: "Privado",
    text_ventas_estado: "Estado",
    text_ventas_fecha: "Fecha",
    text_ventas_createdFecha: "Fecha de creación",
    text_ventas_updateFecha: "Fecha de update",
    text_ventas_medio: "Medio",
    text_ventas_envio: "Envío",
    text_ventas_transportadora: "Transportadora",
    text_ventas_cliente: "Cliente",
    text_ventas_ID: "Identificación",
    text_ventas_copiado: "Copiado!",
    text_ventas_copiarLink: "Copiar link",
    text_ventas_Ver: "Ver",
    text_ventas_sinPrecios: "Sin precios",
    text_ventas_asignar: "Asignar",
    text_ventas_sinPagar: "Sin Pagar",
    text_ventas_pagada: "Pagada",
    text_ventas_rechazada: "Rechazada",
    text_ventas_cancelado: "Cancelado",
    text_ventas_despachado: "Despachado",
    text_ventas_recibido: "Recibido",
    text_ventas_entregado: "Entregado",
    text_ventas_finalizado: "Finalizado",
    text_ventas_cotizacion: "Cotización",
    text_ventas_abandonada: "Abandonada",
    text_ventas_retenida: "Retenida",
    text_ventas_reversada: "Reversada",
    text_ventas_devuelto: "Devuelto",
    text_ventas_enTransito: "En transito",
    text_ventas_enEmpaque: "En empaque",
    text_ventas_procesandoPago: "Procesando pago",
    text_ventas_exportarMisVentas: "Exportar mis ventas",
    text_ventas_descargar: "Descargar ",
    text_ventas_infoDescargar:
      "un listado de tus ventas. Puedes descargarlo en estos formatos XLS , CSV",
    text_ventas_exportarXLS: "Exportar en XLS",
    text_ventas_exportarCSV: "Exportar en CSV",
    //pagos
    text_pagos_epaycoTC: "ePayco TC",
    text_pagos_epaycoPSE: "ePayco PSE",
    text_pagos_epaycoEfectivo: "ePayco efectivo",
    text_pagos_consignación: "Consignación",
    text_pagos_efecty: "Efecty",
    text_pagos_pagaTienda: "Paga en Tienda",
    text_pagos_pagaConvernir: "Pago a convenir",
    text_pagos_epaycoSafetyPay: "ePayco SafetyPay",
    text_pagos_pagaContraentrega: "Pago contraentrega",
    text_pagos_mercadopagoTC: "MercadoPago TC",
    text_pagos_payU: "PayU",
    text_pagos_mercadoPAgo: "MercadoPago",
    text_pagos_ePayco: "ePayco",
    text_pagos_payUTUC: "PayU TC",
    text_pagos_payUEfectivo: "PayU efectivo",
    text_pagos_payUPSE: "PayU PSE",
    text_pagos_mercadopagoPSE: "MercadoPago PSE",
    text_pagos_daviplata: "Daviplata",
    text_pagos_nequi: "Nequi",
    text_pagos_Wompi: "Wompi",
    text_pagos_FLOW: "FLOW",
    text_pagos_TUCOMPRA: "TuCompra",
    text_pagos_wepay4U: "Wepay4U",
    text_pagos_addi: "Addi",
    text_pagos_Hoko: "HOKO",
    text_pagos_Hoko_recaudo: "HOKO (Recaudo en efectivo)",
    // comunidad
    text_comunidad: "📚 Comunidad",
    text_comunidad_partners: "Partners",
    text_comunidad_vacantes: "Vacantes",
    text_comunidad_Perks: "Perks",
    text_comunidad_cupones: "Cupones",
    text_comunidad_Awards: "Premios",
    //TiendaOnline
    text_tiendaOnline_title: "🛒 Tienda Online",
    text_tiendaOnline_cupones: "Cupones",
    text_tiendaOnline_descuentos: "Descuentos",
    text_tiendaOnline_buzon: "Buzón",
    text_tiendaOnline_suscriptores: "Suscriptores",
    text_tiendaOnline_seguridadEditor: "Seguridad editor",
    text_tiendaOnline_controlDeAcceso: "Control de acceso",
    text_tiendaOnline_usarPlantillaEditor: "Usar plantilla del editor",
    text_tiendaOnline_actual: "Actual",
    text_tiendaOnline_infoEditorViejo:
      "Editor de diseño te permite modificar tu plantilla y configurar a tu gusto algunos componentes.",
    text_tiendaOnline_usar: "Usar",
    text_tiendaOnline_demo: "Demo",
    text_tiendaOnline_reset: "Restaurar diseño",
    text_tiendaOnline_usoDiseñoActual:
      "Este es el diseño que usas actualmente en tu tienda",
    text_tiendaOnline_personalizarDiseño: "🎨 Personalizar diseño",
    text_tiendaOnline_verMitienda: "Ver mi tienda",
    text_tiendaOnline_plantillaExclusiva: "Plantilla exclusiva ",
    text_tiendaOnline_usuariosPremium: "para usuarios Premium",
    text_tiendaOnline_quieroPremium: "😃 Quiero ser Premium!",
    text_tiendaOnline_verDemostracion: "Ver demostración",
    text_tiendaOnline_usarPlantilla: "Usar esta plantilla",
    text_tiendaOnline_disponiblePronto: "Disponible muy pronto",
    text_tiendaOnline_usandoPlantilla: "👋 Estás usando esta plantilla",
    text_tiendaOnline_premium: "🏆 Premium",
    text_tiendaOnline_enActualizacion: "⌛ En actualización",
    text_tiendaOnline_nueva: "⭐ Nueva",
    text_tiendaOnline_endesarrolloPronto:
      "⌛ En desarrollo, muy pronto disponible!",
    //cupones
    text_cupones_title: "Cupones de descuento",
    text_cupones_subtitle:
      "Crea códigos de descuento para compartir con tus usuarios.",
    text_cupones_crearCupon: "Crear cupón",
    text_cupones_modificarCupon: "Modificar cupón",
    text_cupones_nombreCupon: "Nombre del cupón",
    text_cupones_valor: "valor",
    text_cupones_porcentaje: "porcentaje",
    text_cupones_codigo: "Código",
    text_cupones_actualizar: "Actualizar",
    text_cupones_cerrarVentana: "Cerrar ventana",
    text_cupones_codigoCupon: "Código cupón",
    text_cupones_nombreCupoin: "Nombre Cupón",
    text_cupones_valorDescuento: "Valor descuento",
    text_cupones_porValor: "Por valor",
    text_cupones_creacion: "Creación",
    text_cupones_activo: "ACTIVO",
    text_cupones_inactivo: "INACTIVO",
    //descuentos
    text_descuentos_crearDescuento: "Crear descuento",
    text_descuentos_editarDescuento: "Editar descuento",
    text_descuentos_cantidadProductos: "Cantidad de productos",
    text_descuentos_crearDescuentoNuevo: "Crear nuevo descuento",
    text_descuentos_descuento: "Descuento ",
    text_tiendaOnline_descuentos_tittle:
      "Crea descuentos para aplicar a las compras.",
    //blog
    text_blog_info: "En Komercia puedes crear tus blog para tu tienda online.",
    text_blog_buscarBlog: "Buscar blog",
    text_blog_crearNblog: "CREAR NUEVO BLOG",
    text_blog_publicado: "Publicado",
    text_blog_editando: "Editando",
    text_blog_listadoArticulos: "Listado de Artículos",
    text_blog_editarArticulos: "Editar artículo ",
    text_blog_portada: "Portada",
    text_blog_subirImagen: "Subir imagen",
    text_blog_tamañoSugerido: "Tamaño sugerido 1500 x 652 pixels",
    text_blog_eliminar: "Eliminar artículo",
    text_blog_titulo: "Título",
    text_blog_faltaTitulo: "Falta el título del artículo",
    text_blog_autor: "Autor",
    text_blog_faltaAutor: "Falta el autor del artículo",
    text_blog_resumen: "Resumen",
    text_blog_faltaResumen: "Falta el resumen del artículo",
    text_blog_contenido: "Contenido",
    text_blog_visible: "Visible",
    text_blog_oculto: "Oculto",
    text_blog_crearArticulo: "Crear artículo",
    //buzon
    text__buzon_title: "Buzón de Mensajes",
    text__buzon_subtitle:
      "Estos son los mensaje que tus clientes te han escrito en el sección de contacto de tu tienda",
    text__buzon_buscarCliente: "Buscar cliente",
    text__buzon_fechaEnvio: "Fecha de Envío:",
    text__buzon_eMail: "Correo Electrónico:",
    text__buzon_telefono: "Telefono:",
    //suscriptores
    text_suscriptores_title: "Mis suscriptores",
    text_suscriptores_fechaSuscripcion: "Fecha de suscripción",
    //cubiko
    text_cubiko_text1: "Gestiona tus ",
    text_cubiko_text2: "productos",
    text_cubiko_text3: " y",
    text_cubiko_text4: "anúncialos",
    text_cubiko_text5: " en diferentes canales de una manera fácil y rápida.",
    text_cubiko_abrirCubiko: "Abrir panel de cubiko",
    text_cubiko_desconectarIntegracion: "Desconectar integración",
    text_cubiko_iniciaFacebbok: "Inicia sesión con Facebook",
    //Whatsapp
    text_whatsapp_title: "👍 Tienda WhatsApp",
    text_whatsapp_diseño: "Plantillas",
    text_whatsapp_diseño2: "🎨 Plantillas",
    text_whatsapp_diseño3: "Plantillas disponible",
    text_whatsapp_diseño4: "Mis sitios",
    text_whatsapp_configuracion: "Configuración",
    text_whatsapp_checkout_dynamic: "Checkout dinámico",
    text_whatsapp_catalogoWhatsApp: " Catálogo WhatsApp",
    text_whatsapp_catalogoWhatsApp_info:
      "Comparte el link de tu catálogo y recibe los pedidos directamente como un mensaje por WhatsApp.",
    text_whatsapp_personalizaDiseño: "Personalizar Diseño",
    text_whatsapp_imagenPortada: "Imagen de portada",
    text_whatsapp_tamañoRecomendado900x300: "Tamaño recomendado 900px x 300px",
    text_whatsapp_cambiar: " Cambiar",
    text_whatsapp_logo: "Logo",
    text_whatsapp_QRtienda: "QR Tienda",
    text_whatsapp_fondoLogo: "Fondo del Logo",
    text_whatsapp_mensajeTienda: "Mensaje de la tienda",
    text_whatsapp_descripcion: "Descripción",
    text_whatsapp_descripcionTiendaWapp: "Descripción de la tienda Whatsapp",
    text_whatsapp_temaTienda: "Tema de la tienda",
    text_whatsapp_tema1: "Tema 1",
    text_whatsapp_tema2: "Tema 2",
    text_whatsapp_tema3: "Tema 3",
    text_whatsapp_estiloCategorias: "Estilo de categorías",
    text_whatsapp_opcion1: "Opción1",
    text_whatsapp_opcion2: "Opción2",
    text_whatsapp_opcion3: "Opción3",
    text_whatsapp_opcion4: "Opción4 (Img)",
    text_whatsapp_subcategory: "Habilitar subcategoría",
    text_whatsapp_estilosProductos: "Estilo de los productos",
    text_whatsapp_botonOnline: "Online payment button",
    text_whatsapp_watermark: "Marca de agua (Komercia)",
    text_whatsapp_watermac: "Botón pago online",
    text_whatsapp_colorBotones: "Color Botones",
    text_whatsapp_colorTextoBotones: "Color texto botones",
    text_whatsapp_colorDegradadoBanner: "Color degradado Banner (Tema 3)",
    text_whatsapp_cuadrado: "Cuadrado",
    text_whatsapp_redondo: "Redondo",
    text_whatsapp_lista: "Lista",
    text_whatsapp_grid: "Grid",
    text_whatsapp_conectaRedeSociales: "Conecta tus redes sociales",
    text_desing_add: "Crear sitio",
    text_desing_edit: "Editar sitio",
    text_desing_delete: "Eliminar sitio",
    //Academia
    //home
    text_empresa_congfiguracion: "👋 Configuración",
    text_empresa_miEmpresa: "Mi empresa",
    text_empresa_redes: "Redes",
    text_empresa_pagos: "Pagos",
    text_empresa_envios: "Envíos",
    text_empresa_sedes: "Sedes",
    text_empresa_legal: "Legal",
    //Empresa
    text_empresa_idTienda: "ID de la tienda",
    text_empresa_nombreTienda: "Nombre de la empresa",
    text_empresa_idioma: "Idioma",
    text_empresa_idiomaTienda: "Selecciona idioma de la tienda",
    text_empresa_moneda: "Moneda",
    text_empresa_seleccionaModenaTienda: "Selecciona la moneda de la tienda",
    text_empresa_pais: "País",
    text_empresa_seleccionaPais: "Selecciona el país",
    text_empresa_departamento: "Departamento",
    text_empresa_estado: "Estado",
    text_empresa_region: "Región",
    text_empresa_municipios: "Municipios",
    text_empresa_rexones: "Rexones",
    text_empresa_provincia: "Provincia",
    text_empresa_distrito: "Distrito",
    text_empresa_categoria: "Categoría",
    text_empresa_palabrasClaves: "Palabras claves",
    text_empresa_ropaCalzado: "ropa, calzado",
    text_empresa_valorMinimoCompra: "Valor mínimo de compra",
    text_empresa_ventaMinima: "Venta minima de compra",
    text_empresa_descripcionTienda: "Descripción de la tienda",
    text_empresa_agregarFotografia: "Agrega una fotografía de tu negocio",
    text_empresa_subdominio: "Subdominio",
    text_empresa_dominio: "dominio",
    text_empresa_tuDominio: "Tu subdominio ha sido configurado",
    text_empresa_necesitasAyuda: "¿Necesitas ayuda?",
    text_empresa_agregarIntegracionesOnline:
      "Agrega las mejores integraciones a tu tienda online",
    text_empresa_irIntegraciones: "Ir a integraciones",
    text_empresa_regimen: "Regimen",
    text_empresa_nit: "NIT",
    //pago
    text_pago_title: "Configuración Opciones de Pago",
    text_pago_subTitle:
      "Selecciona tu pasarela de pagos de preferencia y tus métodos de pago",
    text_meth_pago_subTitle:
      "Selecciona tus métodos de pago para tu tienda online",
    text_opc_pagos: "Pasarelas de pago",
    text_pago_politicasPago: "Políticas de pago",
    text_pago_metodosPago: "Métodos de pago",
    text_pago_politicasInfo:
      "Ocupe este espacio para establecer las políticas, condiciones y/o restricciones asociadas a los metodos de pago que haya elegido.",
    text_pago_epaycoInfo:
      "Acepta pagos en línea desde tu tienda online, ofrece múltiples medios y formas de pago. Tus clientes podrán comprar más seguro, fácil y rápido tus productos.",
    text_pago_camposObligatorios: "Todos los campos son obligatorios",
    text_pago_integracionPagosOnline: "Integración de pagos online",
    text_pago_identificacionEpayco: "Identificación de epayco",
    text_pago_calculadoraTranssacciones: "Calculadora de transacciones",
    text_pago_simuladorTransacciones: "Simulador de transacciones",
    text_pago_mercadoLibreInfo:
      "Con la tecnologia de mercadolibre, hemos integrado su exitosa pasarela de pagos Mercadopago que te permite recibir pagos en 15 paises de latinoamerica.",
    text_pago_mercadoLibreConecta: "Conectar a Mercado pago",
    text_pago_mercadoLibreYaConecta: "Ya estas vinculado a Mercadopago con",
    text_pago_calculaCostos: "Calcula tus costos",
    text_pago_credibancoInfo:
      "Lleva tu negocio al mundo digital, aumenta tus ventas y llega a más clientes, encuentra la solución que se ajuste a tu necesidad y ofrece más opciones de pago online.",
    text_pago_wompiInfo:
      "Compañía del Grupo Bancolombia que presta servicios de pasarela de pago a personas naturales, pequeñas y grandes empresas.",
    text_pago_paymentWay:
      "Empresa FinTech que brinda soluciones de pagos mediante servicios de pasarela para recaudo electrónico, ecosistemas transaccionales, aplicaciones móviles, comercio electrónico, manillas inteligentes y soluciones a la medida.",
    text_pago_verDocumentacion: "Ver documentación.",
    text_pago_iniciarConfiguracion: "Iniciar la configuración",
    text_pago_flowInfo:
      "Flow es una plataforma de pagos online donde podrás pagar y recibir pagos de forma rápida y segura mediante:",
    text_pago_Tucompra:
      "Son la nueva era de pagos en Latinoamérica, crean soluciones de recaudo modernas, eficientes, seguras y rápidas, totalmente competentes para acelerar tu negocio y abren nuevos nichos de mercado, gracias a las nuevas tecnologías. Tu Compra, Tu aliado seguro en el recaudo.",
    text_pago_WePay4U:
      "Con la tecnología de Wepay4U hemos integrado la pasarela de pagos PayCash que permite recibir pagos en todo el país",
    text_pago_metodos: "Métodos",
    text_pago_activar: "Activar",
    text_pago_desactivar: "Desactivar",
    //envios
    text_envios: "Envíos",
    text_envios_info:
      "Configura el método de envío para tu tienda (selecciona una opción)",
    text_envios_politicasEnvios: "Configurar políticas de envío",
    text_envios_metodosEnvio: "Metodos de envío",
    text_envios_sinEnvio: "Desactivado",
    text_envios_sintarifa: "Sin tarifa",
    text_envios_enviosGratis: "Envío Gratis",
    text_envios_tarifaPlana: "Tarifa Plana",
    text_envios_porPrecio: "Por precio",
    text_envios_porCiudad: "Por ciudad",
    text_envios_valorEnvio: "Valor Envío",
    text_envios_para: "Para",
    text_envios_hasta: "Hasta",
    text_envios_agregarRango: "Agregar nuevo rango",
    text_envios_escribeCiudad: "Escribe la ciudad",
    text_envios_restoPais: "Resto del país",
    text_envios_tiempoEntrega: "Tiempo entrega",
    text_envios_dias: "1 días",
    text_envios_addCiudad: "Agregar ciudad",
    text_envios_tarifaPais: "Tarifa para el resto del país",
    text_envios_tarifaPaisInfo:
      "Si activas la tarifa para el resto, se aplicará para el resto de ciudades no especificadas.",
    text_envios_HabilitarRestoPais: "Habilitar Resto del País",
    text_optional: "Opcional",
    text_envios_pagarOnline: "Recoger y pagar en la tienda",
    text_envios_pagarOnlineInfo1:
      "El comprador recoge y paga su compra en tu establecimiento,",
    text_envios_pagarOnlineInfo2: "de esa forma no aplica el costo de envio.",
    text_envios_politicasEnviosT: "Políticas de Envío",
    text_envios_politicasEnvioInfo:
      "Escribe claramente las condiciones para el proceso de envios de tus productos, cobertura de ciudades, restricciones de peso, tamaño y costos adicionales, todo lo necesario para que tu comprador resuelva sus dudas antes de realizar la compra. En caso de que no especifiques todas las restricciones claramente, debes mantener el precio del envio y asumir gastos adicionales.",
    text_envios_tarifaporPrecio: "Tarifa por precio",
    text_envios_tarifaporCiudad: "Tarifa por ciudad",
    text_envios_desc1:
      "Obsequia el envio de productos a tus compradores. Miralo como una inversión, asumes el valor del envio y mejora tus ventas. Es muy recomendado que tengas envios gratis en tu tienda para fidelizar tus clientes y aumentar tus ventas.",
    text_envios_desc2:
      "El valor del envio siempre sera el mismo, sin importar el numero de articulos, peso o precio de la compra. Aparecera un valor fijo para ciudades principales y otro para el resto del país.",
    text_envios_desc3:
      "El valor del envio se calcula dependiendo del precio de la venta realizada. Crea dependiente del valor de la venta y un precio para el envio. El sistema automaticamente cobra el comprador el valor del envio que asignaste a ese rango.",
    text_envios_desc4:
      "El valor del envio se calcula dependiendo del precio de la venta realizada. Crea dependiente del valor de la venta y un precio para el envio. El sistema automaticamente cobra el comprador el valor del envio que asignaste a ese rango.",
    text_envios_desc5:
      "El destinatario asume el costo del envío al momento de recibir el artículo",
    text_envios_desc6:
      "Desactiva el envio a domicilio, el comprador recoge y paga su compra en tu establecimiento, de esa forma no aplica el costo de envio.",
    // text_envios_desc5:
    //   'El valor del envio sera pagado por el cliente cuando reciba el producto.',
    //legal
    text_legal_info1: "Políticas de tratamiento de datos",
    text_legal_info2: "Política de garantia",
    text_legal_info3: "Política de devoluciones / retracto",
    text_legal_info4: "Política de cambio",
    //Integraciones
    text_integraciones_title: "🚀 Integraciones",
    text_integraciones_title2: "Integraciones",
    text_integraciones_disponibles: "Integraciones disponibles",
    text_integraciones_masUsadas: "Las integraciones mas usadas",
    text_integraciones_actualizacion: "Actualización",
    text_integraciones_conectada: "😃 Conectada",
    text_integraciones_noConectada: "No conectada",
    text_integraciones_noDisponible: "⛔ No disponible",
    text_integraciones_proximamente: "⏳ Próximamente",
    text_integraciones_seleccionarPais: "Seleccionar país",
    text_integraciones_seleccionarCategoria: "Seleccionar categoría",
    text_integraciones_disponibleColombia: "Disponible en Colombia",
    text_integraciones_disponibleChile: "Disponible en Chile",
    text_integraciones_disponiblePeru: "Disponible en Perú",
    text_integraciones_disponiblePuertoRico: "Disponible en Puerto Rico",
    text_integraciones_disponibleMexico: "Disponible en México",
    text_integraciones_disponibleEcuador: "Disponible en Ecuador",
    text_integraciones_disponibleArgentina: "Disponible en Argentina",
    text_integraciones_disponiblePanama: "Disponible en Panamá",
    text_integraciones_disponibleTodos: "Disponible para todos",
    text_integraciones_ePayco: "ePayco",
    text_integraciones_ePayco_info:
      "Acepta pagos en línea desde tu tienda online, ofrece múltiples medios y formas de pago. Tus clientes podrán comprar más seguro, fácil y rápido tus productos.",
    text_integraciones_payU: "PayU",
    text_integraciones_mercadoPago: "Mercado pago",
    text_integraciones_mercadoPago_info:
      "Solución de pagos  que permite a vendedores recibir pagos de forma simple y rápida, entre tarjeta de crédito y débito.",
    text_integraciones_credibanCo: "CredibanCo",
    text_integraciones_credibanCo_info:
      "Lleva tu negocio al mundo digital, aumenta tus ventas y llega a más clientes, encuentra la solución que se ajuste a tu necesidad y ofrece más opciones de pago online.",
    text_integraciones_mensajerosUrbanos: "Mensajeros urbanos",
    text_integraciones_mensajerosUrbanos_info:
      "Servicio de mensajería urbana. Te conectamos con el mensajero con moto más cercano en Bogotá, Barranquilla, Cali, y otras ciudades de Colombia.",
    text_integraciones_envioClick: "EnvioClick",
    text_integraciones_envioClick_info:
      "Somos la primera plataforma de generación y gestión masiva de envíos que te conecta con servicios de paquetería y carga e integra tecnología innovadora, inteligencia artificial, y un equipo de expertos para brindarte la logística de envíos más eficiente del mercado.",
    text_integraciones_wompi: "Wompi",
    text_integraciones_wompi_info:
      "Compañía del Grupo Bancolombia que presta servicios de pasarela de pago a personas naturales, pequeñas y grandes empresas.",
    text_integraciones_googleAnalytics: "Google analytics",
    text_integraciones_googleAnalytics_info:
      "Herramienta de analítica web de Google que  permite obtener información  del número de visitantes, y comportamiento en nuestro sitio web.",
    text_integraciones_tidio: "Tidio",
    text_integraciones_tidio_info:
      "Chat online en vivo en tu tienda, con chatbots para sacar el mayor rendimiento cuando no estás disponible, para atender a los usuarios 24/7.",
    text_integraciones_googleManager: "Google Tag Manager",
    text_integraciones_googleManager_info:
      "GTM es un sistema de administración o gestor de etiquetas que nos permite actualizar de forma fácil y rápida todas aquellas etiquetas/ pixels/ códigos dentro de su plataforma.",
    text_integraciones_facebookPixel: "Facebook pixel",
    text_integraciones_facebookPixel_info:
      "Medir las conversiones de nuestras campañas de publicidad en Facebook y el coste de cada una de ellas, ya que podemos configurar el seguimiento asignando un valor monetario.",
    text_integraciones_facebookMessenger: "Facebook Messenger",
    text_integraciones_facebookMessenger_info:
      "Agrega un chat a tu sitio web con Messenger. Con el chat de Messenger, puedes brindar ayuda personalizada.",
    text_integraciones_miPaquete: "Mi paquete",
    text_integraciones_miPaquete_info:
      "Envíos tradicionales, pagos contra entrega, seguimiento y automatización, para entrega de las ventas de tu tienda online.",
    text_integraciones_rocketfy: "Rocketfy",
    text_integraciones_rocketfy_info:
      "Envía a todo el país y cobra contraentrega o digital. Si tienes mensajeros propios, podrás asignarle envíos.",
    text_integraciones_flow: "Flow",
    text_integraciones_flow_info:
      "Flow es una plataforma de pagos online donde podrás pagar y recibir pagos de forma rápida y segura.",
    text_integraciones_cubiko: "Cubiko",
    text_integraciones_cubiko_info:
      "Gestiona tus productos y anúncialos en diferentes canales de una manera fácil y rápida.",
    text_integraciones_paymentsWay: "PaymentsWay",
    text_integraciones_paymentsWay_info:
      "FinTech brinda soluciones de pagos mediante servicios de pasarela para recaudo electrónico.",
    text_integraciones_googleMerchat:
      "Etiqueta HTML generada por google para verificar los servicios de Google, Necesita esta verificación para reclamar el sitio web y obtener la aprobación de sus productos. La etiqueta actúa como un marcador para ayudar a Google a identificar su sitio web y no proporciona acceso ni permisos a su sitio web.",
    //Mi perfil
    text_perfil_miPerfil: "😃 Mi Perfil",
    text_perfil_ajustes: "Ajustes",
    text_perfil_referidos: "Referidos",
    text_perfil_suscription: "Suscripción",
    text_perfil_facturacion: "Facturación",
    text_perfil_contraseña: "Contraseña",
    text_perfil_admin: "Administración",
    text_perfil_users: "Usuarios",
    text_perfil_informacionPerfil: "Información del perfil",
    text_perfil_nombreApellido: "Nombre y Apellido",
    text_perfil_correoElectronico: "Correo Electrónico",
    text_perfil_departamento: "Departamento",
    text_perfil_ciudad: "Ciudad",
    text_perfil_telefono: "Teléfono",
    text_perfil_direccion: "Dirección",
    text_perfil_tipoIdentificacion: "Tipo de identificación",
    text_perfil_numeroIdentificacion: "Numero de identificacion",
    text_perfil_cambiarContraseña: "Cambiar contraseña",
    text_perfil_cambiarEmail: "Cambiar Correo Electrónico",
    text_perfil_contraseñaActuaal: "Tu contraseña actual",
    text_perfil_nuevaContraseña: "Tu nueva contraseña",
    text_perfil_repiteContraseña: "Repite tu nueva contraseña",
    //Etiquetas
    text_etiquetas_tittleEtiquetas: "Etiquetas",
    text_etiquetas_selectEtiquetas: "Seleccionar una etiqueta",
    text_etiquetas_sinEtiquetas: "Sin etiqueta",
    text_etiquetas_grupoEtiquetas: "Grupo de etiquetas",
    text_etiquetas_tagsBtn: "Nueva etiqueta",
    text_etiquetas_posicion: "Posición",
    text_etiquetas_notEtiqueta: "No tiene agregado ninguna etiqueta",
    //referidos
    //suscripcion
    //facturacion
    text_perfil_configuracionFacturacion:
      "Configuración de facturación electronica",
    text_perfil_tipo: "Tipo",
    text_perfil_numeroDocumento: "Número de documento",
    text_perfil_regimen: "Regimen",
    text_perfil_codigoImpuestos: "Código de nivel de impuestos",
    text_perfil_primerNombre: "Primer nombre",
    text_perfil_apellido: "Apellido",
    //footer
    text_footer_derechosReservados: "Derechos reservados",
    text_footer_creadoCon: "Creado con",
    text_footer_enColombia: "en latam.",
    text_footer_lenguaje: "Lenguaje: ",
    text_footer_pais: "País: ",
    text_footer_moneda: "Moneda: ",
    text_footer_idoma: "Idioma: ",
    text_footer_siguenos: "Síguenos: ",
    // header
    text_header_pendiente: "Pendiente",
    text_header_refiereamigo: "o refiere un amigo",
    text_header_publicidad:
      "Gana! 📣 Recomendando a Komercia, Regala meses gratis y obten meses gratis de por vida ✌🏻💯  ",
    text_header_publicidadPrueba0: "Te queda",
    text_header_publicidadPrueba1: "Te quedan",
    text_header_publicidadPrueba2: "día de prueba",
    text_header_publicidadPrueba3: "días de prueba ✌🏻💪🏻",
    text_header_publicidadPrueba4:
      "días de suscripción. Si tienes pago automático no te preocupes, sigue impulsando tus ventas. 🚀",
    text_header_publicidadPrueba5:
      "día de suscripción. Si tienes pago automático no te preocupes, sigue impulsando tus ventas. 🚀",
    text_header_btnrefer: "Refiere Ahora",
    text_header_btnrePubli: "suscríbete ahora",
    text_header_howrefer: "Mas información aqui",
    text_header_emprendedor: "Emprendedor",
    text_header_buscador: "Busqueda ",
    text_header_inventario: "Selecciona tu inventario",
    text_header_verTienda: "Ver tienda",
    text_header_verPage: "Vista previa",
    text_header_cerrarSesion: "Cerrar sesión",
    text_header_loNuevo: "Lo nuevo",
    text_header_apagarTienda: "Apagar tienda",
    //hoko
    text_hoko_activar: "Activar",
    text_hoko_quitar: "Quitar",
    text_hoko_btn: "Seleccionar",
    text_hoko_title: "Selecciona tu tipo de inventario",
    text_hoko_title_text_1: "Inventario propio",
    text_hoko_info1:
      " El inventario la tienda online es la responsable de abastecer el stock, armar los pedidos y gestionar el envío de los mismos.",
    text_hoko_title_text_2: "Inventario Hoko",
    text_hoko_info2:
      "  HOKO te permite el acceso a cientos de proveedores a lo largo de todo el país con miles de productos y cientos de miles de unidades listas para la venta y despacho, adicional puedes tener stocks privados de los productos mas ganadores o de tus proveedores exclusivos.",
    //Importar / Exportar
    text_paymentBlock_title_exportar: "Exportar productos",
    text_paymentBlock_descrip_exportar:
      "Descarga un listado de tus productos propios en Komercia. Puedes descargarlo en formato .xls",
    text_client_descrip_exportar:
      "Descarga un listado de tus clientes propios en Komercia. Puedes descargarlo en formato .xls",
    text_paymentBlock_textbtn_exportar: "Exportar archivo .xls",
    text_paymentBlock_title_importar: "Importar productos",
    text_paymentBlock_descrip_importar:
      "El formato permitido es .xlsx o csv que es una extensión especial de las hojas de cálculo como Excel.",
    text_paymentBlock_textbtn_importar: "Importar archivo .xls",
    text_paymentBlock_advertencia: "Advertencia!",
    text_paymentBlock_text_advertencia:
      "Has superado el límite de productos de tu plan, debes de volver a renovar la membresía.",
    text_paymentBlock_renovar: "Renovar membresía",
    text_paymentBlock_brear_text1: "Regresar > Importar / Exportar >",
    text_paymentBlock_brear_text2: "Importar archivo",
    text_paymentBlock_confirmar: "Confirmar",
    // estadisticas
    text_statistics_tittle: "Estadísticas",
    // etiqueta
    text_etiquetas_title: "Asignar etiqueta",
    text_etiquetas_newTag: "Nueva etiqueta",
    text_etiquetas_assingedTag: "Etiquetas asignadas",
    //helpDesk
    text_helpDesk_Aprende: "Aprende más",
    text_helpDesk_diseño: "Diseño",
  },
  en: {
    //paymentExpired
    text_payment_expired_title: "Your subscription is expired",
    text_payment_expired_description:
      "Some features are blocked, renew your subscription to access all content and services.",
    text_payment_expired_btn: "My Subscription",
    text_payment_limitProducts: "You have exceeded the product limit",
    //menu
    text_nosesion_title: "Log in again",
    text_nosesion_subtitle:
      "Your session has ended due to inactivity. You must log in again to access your store´s administrator panel.",
    text_nosesion_iniciar: "Sign in",
    text_inicio: "Home",
    text_inventario: "Inventory",
    text_ventas: "Sales",
    text_analytics: "Analytics",
    text_estadisticas: "📊 Statistics",
    text_analyticsProduct: "📦 Products",
    text_tienda_online: "Online Store",
    text_whatsapp: "WhatsApp",
    text_academia: "Academy",
    text_LinkKomercia: "Link Komercia",
    text_empresa: "Company",
    text_regalo: "Awards",
    text_integraciones: "Integrations",
    text_mi_perfil: "My profile",
    text_ayuda: "Help",
    text_salir: "Exit",
    text_inventario2: "📦 Inventory",
    text_categorias: "Categories",
    text_tags: "Tags",
    text_papelera: "Trash",
    text_guardaCambios: "Save changes",
    text_guardadoCorrectamente: "Saving successfully",
    //HelpDesk
    text_helpDesk: "HelpDesk",
    text_helpDesk2: "📎 HelpDesk",
    text_helpDesk_listado: "Articles",
    text_helpDesk_editor: `Editor's template`,
    text_helpDesk_congfiguracion: "Configuration",
    //Inicio
    text_inicio_visitar: "Visits",
    text_inicio_totales: "totals",
    text_inicio_productos: "Products",
    text_inicio_listDropshipping: "Dropshipping products",
    text_inicio_publicados: "published",
    text_inicio_ventas: "Sales",
    text_inicio_generadas: "generated",
    text_inicio_valor: "Value",
    text_inicio_total: "total",
    text_inicio_ingresos: "Revenue",
    text_inicio_nuevaIntegracion: "New Integration!",
    text_inicio_facebook_info:
      "ssist customers browsing your website with the free Facebook chat plugin to get sales.",
    text_inicio_capacitacion: "Learn more about",
    text_inicio_capacitacion2:
      "and its features, get the most out of your plan and improve your online store with us.",
    text_inicio_conectar: "Connect",
    text_inicio_participar: "I want to participate",
    text_inicio_cursoRecomendados: "Recommended courses",
    text_inicio_ver_cursoRecomendados: "View all courses",
    text_inicio_videosRecomendados: "Recommended videos",
    text_inicio_ver_videosRecomendados: "View all videos",
    text_inicio_video_info1:
      "Product shipment management - Envioclick Alliance",
    text_inicio_video_info2: "Digital transformation in Big San entrepreneurs",
    text_inicio_video_info3: "WhatsApp Catalog",
    text_inicio_video_info4: "Komercia Chile",
    text_inicio_video_info5:
      "Learn how to create your online store from scratch online",
    text_inicio_video_info6: "Landing digital marketing with Luis Betancourt",
    text_inicio_video_info7: "Basic product photography course",
    text_inicio_video_info8: "Facebook and Instagram Ads course",
    text_inicio_blogRecomendados: "blogs",
    text_inicio_ver_blogRecomendados: "View all articles",
    text_inicio_blog_info1:
      "Complete Guide to enable Instagram Shopping with your online store",
    text_inicio_blog_info2:
      "What are the Banner Sizes of the online stores created on Komercia.co?",
    text_inicio_blog_info3: "Integrate the Facebook Pixel in your online store",
    text_inicio_blog_info4: "Facebook Messenger integration",
    text_inicio_hola: "Hello, ",
    text_inicio_tuTiendaOnline: "Your online store:",
    text_inicio_tuTiendaOnlineEstaCerrada:
      "Your store is closed and cannot receive sales",
    text_inicio_abrirTienda: "Open Store",
    //Inventario
    text_inventario_1: "Facebook Messenger integration",
    text_inventario_duplicar: "Duplicate",
    text_inventario_ver: " See",
    text_inventario_aprendeMas: "Learn more about ",
    //categorias
    text_inventario_probar: "Test",
    text_inventario_editar: "Edit",
    text_inventario_elimnar: "Delete",
    text_inventario_previsualizar: "Preview",
    text_inventario_restablecar: "Reset",
    text_inventario_nombre: "Name",
    text_inventario_numeroProductos: "Number of products",
    text_inventario_copiarUrl: "Copy URL",
    text_inventario_escribeCategoria: "Type the category",
    text_inventario_urlCategoriaCopiada: "copy category url!",
    text_inventario_copiar: "Copy",
    text_inventario_escribeSubCategoria: "Type the subcategory",
    text_inventario_noHayCategorias: "No categories",
    text_inventario_agregarCategoriaLista: "Add a category to the list",
    text_inventario_NuevaCategoria: "New category",
    text_inventario_subNuevaCategoria: "New subcategory",
    text_inventario_editarCategoria: "Edit category",
    text_inventario_editarSubCategoria: "Edit subcategory",
    text_inventario_agregarSubCategoriaLista:
      "Adds a new subcategory to the list",
    text_inventario_seleccionaCategoria: "Select the category",
    text_inventario_seleccionaSubCategoria: "Select the subcategory",
    text_inventario_aprendeSobreCategorias: "Learn more about categories",
    //papelera
    text_inventario_papelera: "Garbage can",
    text_inventario_tienes: "You have",
    text_inventario_prodcuctosQueNoEstanPublicados:
      "products that are not published in your store",
    text_inventario_yaSuperaste: "You have already exceeded the",
    text_inventario_actualizaTuPLan: "products, update your plan",
    text_inventario_eliminarSeleccionados: "Delete selected products",
    text_inventario_publicarSeleccionados: "Publish selected products",
    text_inventario_publicar: "Publish",
    text_inventario_id: "Id",
    text_inventario_foto: "Photo",
    //productos
    text_inventario_cancelar: "Cancel",
    text_inventario_guardar: "Save",
    text_inventario_cargando: "Loading...",
    text_inventario_fotoProducto: "Product picture",
    text_inventario_subirOtraImagen: "Upload another image",
    text_inventario_videoProducto: "Product video (optional)",
    text_inventario_URLvideoYoutube: "Youtube video URL",
    text_inventario_lisdatoProducto: "Product listing",
    text_inventario_productoN: "Product N°",
    text_inventario_organizar: "Organize",
    text_inventario_textCrearOrganizaProductos:
      "Create categories and organize the products in your store to make it easier for your customers to find them.",
    text_inventario_filtrar: "Filter",
    text_inventario_textFiltrar:
      "Create tags to filter the products in your store to make it easier for your customers to find them.",
    text_inventario_subCategorias: "Subcategories",
    text_inventario_sinCategorias: "No category",
    text_inventario_sinSubCategorias: "No subcategory",
    text_inventario_favoritos: "Favorites",
    text_inventario_peso: "Weight",
    text_inventario_largo: "Length",
    text_inventario_ancho: "Width",
    text_inventario_alto: "High",
    text_inventario_garantia: "Warranty",
    text_inventario_garantiaProducto: "Product Warranty",
    text_inventario_marca: "Brand",
    text_inventario_palabrasClave: "Key words",
    text_inventario_añadirFavoritos: "⭐ Add to favorites",
    text_inventario_añadidoFavoritos: "⭐ Added to favorites",
    text_inventario_envioGratis: "Free Shipping",
    text_inventario_recomendado: "Recommended",
    text_inventario_productosConEnviosGratis:
      "products with free shipping increase your chances of sale",
    text_inventario_no: "No",
    text_inventario_si: "Yes",
    text_inventario_descriptionCorta: "Short description (140 characters)",
    text_inventario_descriptionCortaMSG: "Short description",
    text_inventario_requerido: "Required",
    text_inventario_description: "Description",
    text_inventario_opcional: "Optional",
    text_inventario_priopridadAtienda: "Priority of appearance in the store",
    text_inventario_priopridadAtiendaMSG:
      "Products with high priority (10) will appear in the first positions in your store.",
    text_inventario_deseaAplicarDescuento:
      "Do you want to apply simulated discount to the product?",
    text_inventario_deseaAplicarDescuentoreal:
      "Do you want to apply real discount to the product?",
    text_inventario_ValorPorcentaje: "Percentage value",
    text_inventario_nota: "Note: ",
    text_inventario_notaMsg:
      "The percentage value must not reach 100%, maximum 99%",
    text_inventario_escribirMensajeProducto:
      "Write a message next to this product?",
    text_inventario_escribirMensajeProductoMSG:
      "Is it mandatory to fill in the message by the buyer?",
    text_inventario_etiquetaInstrucciones: "Label/Instructions for the message",
    text_inventario_productoVariantes: "Product without variants",
    text_inventario_referencia: "Reference (optional)",
    text_inventario_SKU: "SKU",
    text_inventario_infoVariantes: "Variants are additional product features.",
    text_inventario_porEjemplo: "For example:",
    text_inventario_infoVariantes2:
      "size, material or color, which allow you to organize your inventory.",
    text_inventario_crearVariantes: "Create variants",
    text_inventario_infoVariantes3:
      "Add variants if this product comes in multiple versions, such as different sizes or colors.",
    text_inventario_nombreOpcion: "Option name",
    text_inventario_ValoresOpcion: "Option values",
    text_inventario_escribeOpcion: "Type the option",
    text_inventario_cambiarNombre:
      "Change name. Remove spaces at the beginning and at the end",
    text_inventario_agregar: "Add",
    text_inventario_agregarVariantes: "Add variant",
    text_inventario_modificarVariantes: "Modify the variants to be created:",
    text_inventario_variantes: "Variant",
    text_inventario_referencia2: "Reference",
    text_inventario_mejoraTuPlan: "Improve your plan now",
    text_inventario_generarDescription: "Generating description",
    text_inventario_generandoDescription: "Generating description",
    text_inventario_inteligenciaArtificial: "Artificial Intelligence",
    text_inventario_inteligenciaArtificial_description:
      "To generate a product description using artificial intelligence you must enter some keywords.",
    text_inventario_palabrasClaves: "Key words",
    text_inventario_seleccionarIdioma: "Select language",
    //Ventas
    text_ventas_buscarProducto: "Search Product",
    text_ventas_todos: "All",
    text_ventas_exportar: "Export",
    text_ventas_importar: "Import",
    text_ventas_ver_productos: "View products",
    text_ventas_editar_productos: "Edit products",
    text_ventas_agotados: "Out of stock",
    text_ventas_nuevo_producto: "New product",
    text_ventas_nombre_producto: "Product name",
    text_ventas_combinacion: "Combination",
    text_ventas_unidades: "Units",
    text_ventas_precio: "Price",
    text_ventas_accciones: "Actions",
    text_ventas_agotado: "SOLD OUT",
    text_ventas_producto_variantes: "Product with variants",
    text_ventas_variantes_desactivas: "Variants disabled",
    text_ventas_state_sales: "Sales status",
    text_ventas_state_shipment: "Shipment status",
    //menu
    text_ventas_title: "💰 Sales",
    text_ventas_listado: "Sales List",
    text_ventas_listado_hoko: "Listing Hoko",
    text_ventas_ventaRapida: "Quick sale",
    text_ventas_clientes: "Customers",
    text_ventas_clientes2: "👤 Customers",
    //tablet
    text_ventas_misVentas: "My Sales",
    text_ventas_misVentas_hoko: "My Sales Hoko",
    text_ventas_filtrarNOrden: "Filter by Order No.",
    text_ventas_BUscarNOrden: "Search Order No.",
    text_ventas_filtrarEstado: "Filter by status",
    text_ventas_filtrarMethodPay: "Filter by payment method",
    text_ventas_seleccionaEstado: "Select a status",
    text_ventas_filtrarFecha: "Filter by date",
    text_ventas_seleccionaFecha: "Select a date",
    text_ventas_filtrarCliente: "Filter by customer",
    text_ventas_filtrarID: "Filter by ID",
    text_ventas_seleccionaCliente: "Select a customer",
    text_ventas_exportarVentas: "Export sales",
    text_ventas_visibilidad: "Visible",
    text_ventas_publico: "Public",
    text_ventas_privado: "Private",
    text_ventas_estado: "Status",
    text_ventas_fecha: "Date",
    text_ventas_medio: "Medium",
    text_ventas_envio: "Send to",
    text_ventas_transportadora: "Conveyor",
    text_ventas_cliente: "Customer",
    text_ventas_ID: "Identification",
    text_ventas_copiado: "Copied!",
    text_ventas_copiarLink: "Copy link",
    text_ventas_Ver: "See",
    text_ventas_sinPrecios: "No prices",
    text_ventas_asignar: "Assign",
    text_ventas_sinPagar: "Unpaid",
    text_ventas_pagada: "Paid",
    text_ventas_rechazada: "Declined",
    text_ventas_cancelado: "Cancelled",
    text_ventas_despachado: "Dispatched",
    text_ventas_recibido: "Received",
    text_ventas_entregado: "Delivered",
    text_ventas_finalizado: "Completed",
    text_ventas_cotizacion: "Quote",
    text_ventas_abandonada: "Abandoned",
    text_ventas_retenida: "Withheld",
    text_ventas_reversada: "Reversed",
    text_ventas_devuelto: "Returned",
    text_ventas_enTransito: "In transit",
    text_ventas_enEmpaque: "In packing",
    text_ventas_procesandoPago: "Payment processing",
    text_ventas_exportarMisVentas: "Export my sales",
    text_ventas_descargar: "Download ",
    text_ventas_infoDescargar:
      "a list of your sales. You can download it in these formats XLS , CSV",
    text_ventas_exportarXLS: "Export in XLS",
    text_ventas_exportarCSV: "Export in CSV",
    //pagos
    text_pagos_epaycoTC: "ePayco TC",
    text_pagos_epaycoPSE: "ePayco PSE",
    text_pagos_epaycoEfectivo: "ePayco cash",
    text_pagos_consignación: "Consignment",
    text_pagos_efecty: "Efecty",
    text_pagos_pagaTienda: "Pay in Store",
    text_pagos_pagaConvernir: "Payment on request",
    text_pagos_epaycoSafetyPay: "ePayco SafetyPay",
    text_pagos_pagaContraentrega: "Cash on delivery",
    text_pagos_mercadopagoTC: "Mercadopago TC",
    text_pagos_mercadoPAgo: "MercadoPago",
    text_pagos_ePayco: "ePayco",
    text_pagos_payU: "Payu",
    text_pagos_payUTUC: "Payu TC",
    text_pagos_payUEfectivo: "Payu Cash",
    text_pagos_payUPSE: "Payu PSE",
    text_pagos_mercadopagoPSE: "Mercadopago PSE",
    text_pagos_daviplata: "Daviplata",
    text_pagos_nequi: "Nequi",
    text_pagos_Wompi: "Wompi",
    text_pagos_FLOW: "FLOW",
    text_pagos_TUCOMPRA: "TuCompra",
    text_pagos_wepay4U: "Wepay4U",
    text_pagos_addi: "Addi",
    text_pagos_Hoko: "HOKO",
    text_pagos_Hoko_recaudo: "HOKO (Cash collection)",
    //TiendaOnline
    text_tiendaOnline_title: "🛒 Online Store",
    text_tiendaOnline_cupones: "Coupons",
    text_tiendaOnline_descuentos: "Discounts",
    text_tiendaOnline_buzon: "Mailbox",
    text_tiendaOnline_suscriptores: "Subscribers",
    text_tiendaOnline_seguridadEditor: "Security editor",
    text_tiendaOnline_controlDeAcceso: "Access Control",
    text_tiendaOnline_usarPlantillaEditor: "Use editor template",
    text_tiendaOnline_actual: "Current",
    text_tiendaOnline_infoEditorViejo:
      "Design editor allows you to modify your template and configure some components to your liking.",
    text_tiendaOnline_usar: "Use",
    text_tiendaOnline_demo: "Demo",
    text_tiendaOnline_reset: "Restore design",
    text_tiendaOnline_usoDiseñoActual:
      "This is the design you are currently using in your store.",
    text_tiendaOnline_personalizarDiseño: "🎨 Customize design",
    text_tiendaOnline_verMitienda: "View my store",
    text_tiendaOnline_plantillaExclusiva: "Exclusive template ",
    text_tiendaOnline_usuariosPremium: "for Premium users",
    text_tiendaOnline_quieroPremium: "😃 I want to be Premium!",
    text_tiendaOnline_verDemostracion: "View demo",
    text_tiendaOnline_usarPlantilla: "Use this template",
    text_tiendaOnline_disponiblePronto: "Available soon",
    text_tiendaOnline_usandoPlantilla: "👋 You are using this template",
    text_tiendaOnline_premium: "🏆 Premium",
    text_tiendaOnline_enActualizacion: "⌛ In update",
    text_tiendaOnline_nueva: "⭐ New",
    text_tiendaOnline_endesarrolloPronto: "⌛ In development, coming soon!",
    // comunidad
    text_comunidad: "📚 Community",
    text_comunidad_partners: "Partners",
    text_comunidad_vacantes: "Vacancies",
    text_comunidad_Perks: "Perks",
    text_comunidad_cupones: "Coupons",
    text_comunidad_Awards: "Premios",
    //cupones
    text_cupones_title: "Discount Coupons",
    text_cupones_subtitle: "Create discount codes to share with your users.",
    text_cupones_crearCupon: "Create coupon",
    text_cupones_modificarCupon: "Modify coupon",
    text_cupones_nombreCupon: "Coupon name",
    text_cupones_valor: "value",
    text_cupones_porcentaje: "percentage",
    text_cupones_codigo: "Code",
    text_cupones_actualizar: "Update",
    text_cupones_cerrarVentana: "Close window",
    text_cupones_codigoCupon: "Coupon Code",
    text_cupones_nombreCupoin: "Coupon Name",
    text_cupones_valorDescuento: "Discount value",
    text_cupones_porValor: "By value",
    text_cupones_creacion: "Creation",
    text_cupones_activo: "ACTIVE",
    text_cupones_inactivo: "INACTIVE",
    //descuentos
    text_descuentos_crearDescuento: "Create discount",
    text_descuentos_editarDescuento: "Edit discount",
    text_descuentos_cantidadProductos: "Quantity of products",
    text_descuentos_crearDescuentoNuevo: "Create new discount",
    text_descuentos_descuento: "Discount ",
    text_tiendaOnline_descuentos_tittle:
      "Create discounts to apply to purchases.",
    //blog
    text_blog_info:
      "In Komercia you can create your blog for your online store.",
    text_blog_buscarBlog: "Search blog",
    text_blog_crearNblog: "CREATE NEW BLOG",
    text_blog_publicado: "Published",
    text_blog_editando: "Editing",
    text_blog_listadoArticulos: "List of articles",
    text_blog_editarArticulos: "Edit article ",
    text_blog_portada: "Cover page",
    text_blog_subirImagen: "Upload image",
    text_blog_tamañoSugerido: "Suggested size 1500 x 652 pixels",
    text_blog_eliminar: "Remove article",
    text_blog_titulo: "Title",
    text_blog_faltaTitulo: "Missing article title",
    text_blog_autor: "Author",
    text_blog_faltaAutor: "Missing article author",
    text_blog_resumen: "Abstract",
    text_blog_faltaResumen: "Missing article abstract",
    text_blog_contenido: "Content",
    text_blog_visible: "Visible",
    text_blog_oculto: "Hidden",
    text_blog_crearArticulo: "Create article",
    //buzon
    text__buzon_title: "Message Box",
    text__buzon_subtitle:
      "These are the messages that your customers have written to you in the contact section of your store",
    text__buzon_buscarCliente: "Customer Search",
    text__buzon_fechaEnvio: "Date sent:",
    text__buzon_eMail: "Email:",
    text__buzon_telefono: "Phone:",
    //suscriptores
    text_suscriptores_title: "My subscribers",
    text_suscriptores_fechaSuscripcion: "Subscription Date",
    //cubiko
    text_cubiko_text1: "Manage your ",
    text_cubiko_text2: "products",
    text_cubiko_text3: " and ",
    text_cubiko_text4: "advertise",
    text_cubiko_text5: " them in different channels in an easy and fast way.",
    text_cubiko_abrirCubiko: "Open cubiko panel",
    text_cubiko_desconectarIntegracion: "Disconnect integration",
    text_cubiko_iniciaFacebbok: "Login with Facebook",
    //Whatsapp
    text_whatsapp_title: "👍 WhatsApp Store",
    text_whatsapp_diseño: "Templates",
    text_whatsapp_diseño2: "🎨 Templates",
    text_whatsapp_diseño3: "Templates available",
    text_whatsapp_diseño4: "My sites",
    text_whatsapp_configuracion: "Configuration",
    text_whatsapp_checkout_dynamic: "Dynamic checkout",
    text_whatsapp_catalogoWhatsApp: "  WhatsApp Catalog",
    text_whatsapp_catalogoWhatsApp_info:
      "Share your catalog link and receive orders directly as a WhatsApp message.",
    text_whatsapp_personalizaDiseño: "Customize Design",
    text_whatsapp_imagenPortada: "Cover image",
    text_whatsapp_tamañoRecomendado900x300: "Recommended size 900px x 300px",
    text_whatsapp_cambiar: " Change",
    text_whatsapp_logo: "Logo",
    text_whatsapp_QRtienda: "QR Store",
    text_whatsapp_fondoLogo: "Logo Background",
    text_whatsapp_mensajeTienda: "Store Message",
    text_whatsapp_descripcion: "Description",
    text_whatsapp_descripcionTiendaWapp: "Whatsapp Store Description",
    text_whatsapp_temaTienda: "Store Theme",
    text_whatsapp_tema1: "Theme 1",
    text_whatsapp_tema2: "Theme 2",
    text_whatsapp_tema3: "Theme 3",
    text_whatsapp_estiloCategorias: "Category style",
    text_whatsapp_opcion1: "Option1",
    text_whatsapp_opcion2: "Option2",
    text_whatsapp_opcion3: "Option3",
    text_whatsapp_opcion4: "Option4 (Img)",
    text_whatsapp_subcategory: "Enable subcategory",
    text_whatsapp_estilosProductos: "Products style",
    text_whatsapp_botonOnline: "Online payment button",
    text_whatsapp_watermark: "Watermark (Komercia)",
    text_whatsapp_colorBotones: "Buttons color",
    text_whatsapp_colorTextoBotones: "Buttons text color",
    text_whatsapp_colorDegradadoBanner: "Color gradient Banner (Theme 3)",
    text_whatsapp_cuadrado: "Square",
    text_whatsapp_redondo: "Round",
    text_whatsapp_lista: "List",
    text_whatsapp_grid: "Grid",
    text_whatsapp_conectaRedeSociales: "Connect your social networks",
    text_desing_add: "Create site",
    text_desing_edit: "Edit site",
    text_desing_delete: "Delete site",
    //Academia
    //home
    text_empresa_congfiguracion: "👋 Configuration",
    text_empresa_miEmpresa: "My Company",
    text_empresa_redes: "Networks",
    text_empresa_pagos: "Payments",
    text_empresa_envios: "Shipping",
    text_empresa_sedes: "Locations",
    text_empresa_legal: "Legal",
    //Empresa
    text_empresa_idTienda: "Store ID",
    text_empresa_nombreTienda: "Company Name",
    text_empresa_idioma: "Language",
    text_empresa_idiomaTienda: "Select store language",
    text_empresa_moneda: "Currency",
    text_empresa_seleccionaModenaTienda: "Select store currency",
    text_empresa_pais: "Country",
    text_empresa_seleccionaPais: "Select country",
    text_empresa_departamento: "Department",
    text_empresa_estado: "State",
    text_empresa_region: "Region",
    text_empresa_municipios: "Municipalities",
    text_empresa_rexones: "Rexones",
    text_empresa_provincia: "Province",
    text_empresa_distrito: "District",
    text_empresa_categoria: "Category",
    text_empresa_palabrasClaves: "Key words",
    text_empresa_ropaCalzado: "clothing, footwear",
    text_empresa_valorMinimoCompra: "Minimum purchase value",
    text_empresa_ventaMinima: "Minimum purchase value",
    text_empresa_descripcionTienda: "Store description",
    text_empresa_agregarFotografia: "Add a picture of your business",
    text_empresa_subdominio: "Subdomain",
    text_empresa_dominio: "domain",
    text_empresa_tuDominio: "Your subdomain has been set up",
    text_empresa_necesitasAyuda: "Need help?",
    text_empresa_agregarIntegracionesOnline:
      "Add the best integrations to your online store",
    text_empresa_irIntegraciones: "Go to integrations",
    text_empresa_regimen: "Regimen",
    text_empresa_nit: "NIT",
    //pago
    text_pago_title: "Payment Options Configuration",
    text_pago_subTitle:
      "Select your preferred payment gateway and payment methods.",
    text_meth_pago_subTitle:
      "Select your payment methods for your online store",
    text_opc_pagos: "Payment gateways",
    text_pago_politicasPago: "Payment Policies",
    text_pago_metodosPago: "Payment Methods",
    text_pago_politicasInfo:
      "Use this space to set the policies, conditions and/or restrictions associated with the payment methods you have chosen.",
    text_pago_epaycoInfo:
      "Accept online payments from your online store, offer multiple payment methods and methods of payment. Your customers will be able to buy your products safer, easier and faster.",
    text_pago_camposObligatorios: "All fields are required",
    text_pago_integracionPagosOnline: "Online payment integration",
    text_pago_identificacionEpayco: "Identification of epayco",
    text_pago_calculadoraTranssacciones: "Transaction calculator",
    text_pago_simuladorTransacciones: "Transaction simulator",
    text_pago_mercadoLibreInfo:
      "With mercadolibre technology, we have integrated their successful payment gateway Mercadopago that allows you to receive payments in 15 countries in Latin America.",
    text_pago_mercadoLibreConecta: "Connect to Mercado Pago",
    text_pago_mercadoLibreYaConecta:
      "You are already linked to Mercadopago with",
    text_pago_calculaCostos: "Calculate your costs",
    text_pago_credibancoInfo:
      "Take your business to the digital world, increase your sales and reach more customers, find the solution that fits your needs and offers more online payment options.",
    text_pago_wompiInfo:
      "Company of the Bancolombia Group that provides payment gateway services to individuals, small and large companies.",
    text_pago_paymentWay:
      "FinTech company that provides payment solutions through gateway services for electronic collection, transactional ecosystems, mobile applications, e-commerce, smart handles and customized solutions.",
    text_pago_verDocumentacion: "See documentation.",
    text_pago_iniciarConfiguracion: "Start configuration",
    text_pago_flowInfo:
      "Flow is an online payment platform where you can pay and receive payments quickly and securely using:",
    text_pago_Tucompra:
      "They are the new era of payments in Latin America, creating modern, efficient, secure and fast collection solutions, fully competent to accelerate your business and open new market niches, thanks to new technologies. Tu Compra, Your secure partner in the collection.",
    text_pago_WePay4U:
      "With Wepay4U´s technology we have integrated the PayCash payment gateway that allows receiving payments nationwide.",
    text_pago_metodos: "Methods",
    text_pago_activar: "Activate",
    text_pago_desactivar: "Deactivate",
    //envios
    text_envios: "Shipping",
    text_envios_info:
      "Configure the shipping method for your store (select an option)",
    text_envios_politicasEnvios: "Configure shipping policies",
    text_envios_metodosEnvio: "Shipping Methods",
    text_envios_sinEnvio: "Disabled",
    text_envios_sintarifa: "No shipping rate",
    text_envios_enviosGratis: "Free Shipping",
    text_envios_tarifaPlana: "Flat Rate",
    text_envios_porPrecio: "By price",
    text_envios_porCiudad: "By City",
    text_envios_valorEnvio: "Shipping Value",
    text_envios_para: "Para",
    text_envios_hasta: "Hasta",
    text_envios_agregarRango: "Add new range",
    text_envios_escribeCiudad: "Type the city",
    text_envios_restoPais: "Rest of the country",
    text_envios_tiempoEntrega: "Delivery time",
    text_envios_dias: "1 day",
    text_envios_addCiudad: "Add city",
    text_envios_tarifaPais: "Rate for the rest of the country",
    text_envios_tarifaPaisInfo:
      "If you enable the rest of the country rate, it will be applied for the rest of the cities not specified.",
    text_envios_HabilitarRestoPais: "Enable Rest of Country",
    text_optional: "Optional",
    text_envios_pagarOnline: "Pick up and pay at the store",
    text_envios_pagarOnlineInfo1:
      "The buyer picks up and pays for his purchase in your store,",
    text_envios_pagarOnlineInfo2: "that way the shipping cost does not apply.",
    text_envios_politicasEnviosT: " Shipping Policies",
    text_envios_politicasEnvioInfo:
      "Write clearly the conditions for the shipping process of your products, city coverage, weight restrictions, size and additional costs, everything necessary for your buyer to resolve their doubts before making the purchase. In case you do not specify all the restrictions clearly, you must keep the shipping price and assume additional costs.",
    text_envios_tarifaporPrecio: "Rate per price",
    text_envios_tarifaporCiudad: "Rate per city",
    text_envios_desc1:
      "Give the shipping of products to your buyers as a gift. Look at it as an investment, you assume the value of shipping and improve your sales. It is highly recommended to have free shipping in your store to build customer loyalty and increase your sales.",
    text_envios_desc2:
      "The value of shipping will always be the same, regardless of the number of items, weight or price of the purchase. There will be a fixed value for main cities and another one for the rest of the country.",
    text_envios_desc3:
      "he shipping value is calculated depending on the price of the sale made. It creates dependent on the value of the sale and a price for shipping. The system automatically charges the buyer the shipping value you assigned to that range.",
    text_envios_desc4:
      "The shipping value is calculated depending on the price of the sale made. Creates dependent on the value of the sale and a price for shipping. The system automatically charges the buyer the shipping value you assigned to that range.",
    text_envios_desc5:
      "The value of the shipment will be paid by the customer upon receipt of the package.",
    text_envios_desc6:
      "Disable home delivery, the buyer picks up and pays for his purchase at your store, that way the shipping cost does not apply",
    //legal
    text_legal_info1: "Data processing policies",
    text_legal_info2: "Warranty policy",
    text_legal_info3: "Return / Withdrawal Policy",
    text_legal_info4: "Exchange policy",
    //Integraciones
    text_integraciones_title: "🚀 Integrations",
    text_integraciones_title2: "Integrations",
    text_integraciones_disponibles: "Available integrations",
    text_integraciones_masUsadas: "Most used integrations",
    text_integraciones_actualizacion: "Upgrade",
    text_integraciones_conectada: "😃 Connected",
    text_integraciones_noConectada: "Not connected",
    text_integraciones_noDisponible: "⛔ Not available",
    text_integraciones_proximamente: "⏳ Coming soon",
    text_integraciones_seleccionarPais: "Select country",
    text_integraciones_seleccionarCategoria: "Select category",
    text_integraciones_disponibleColombia: "Available in Colombia",
    text_integraciones_disponibleChile: "Available in Chile",
    text_integraciones_disponiblePeru: "Available in Perú",
    text_integraciones_disponiblePuertoRico: "Available in Puerto Rico",
    text_integraciones_disponibleMexico: "Available in México",
    text_integraciones_disponibleEcuador: "Available in Ecuador",
    text_integraciones_disponibleArgentina: "Available in Argentina",
    text_integraciones_disponiblePanama: "Available in Panamá",
    text_integraciones_disponibleTodos: "Available for all",
    text_integraciones_ePayco: "ePayco",
    text_integraciones_ePayco_info:
      "Accept online payments from your online store, offering multiple payment methods and methods of payment. Your customers will be able to buy your products safer, easier and faster.",
    text_integraciones_payU: "PayU",
    text_integraciones_payU_info:
      "Acepta pagos en línea desde tu tienda online, ofrece múltiples medios y formas de pago. Tus clientes podrán comprar más seguro, fácil y rápido tus productos.",
    text_integraciones_mercadoPago: "Mercado pago",
    text_integraciones_mercadoPago_info:
      "Payment solution that allows sellers to receive payments in a simple and fast way, between credit and debit cards.",
    text_integraciones_credibanCo: "CredibanCo",
    text_integraciones_credibanCo_info:
      "Take your business to the digital world, increase your sales and reach more customers, find the solution that fits your needs and offers more online payment options.",
    text_integraciones_mensajerosUrbanos: "Mensajeros urbanos",
    text_integraciones_mensajerosUrbanos_info:
      "Urban courier service. We connect you with the nearest motorcycle courier in Bogota, Barranquilla, Cali, and other cities in Colombia.",
    text_integraciones_envioClick: "EnvioClick",
    text_integraciones_envioClick_info:
      "We are the first platform for massive shipment generation and management that connects you with parcel and freight services and integrates innovative technology, artificial intelligence, and a team of experts to provide you with the most efficient shipment logistics in the market.",
    text_integraciones_wompi: "Wompi",
    text_integraciones_wompi_info:
      "Company of the Bancolombia Group that provides payment gateway services to individuals, small and large companies.",
    text_integraciones_googleAnalytics: "Google analytics",
    text_integraciones_googleAnalytics_info:
      "Google is web analytics tool that allows us to obtain information on the number of visitors and behavior on our website.",
    text_integraciones_tidio: "Tidio",
    text_integraciones_tidio_info:
      "Live online chat in your store, with chatbots to get the best performance when you are not available, to serve users 24/7.",
    text_integraciones_googleManager: "Google Tag Manager",
    text_integraciones_googleManager_info:
      "GTM is a management system or tag manager that allows us to easily and quickly update all those tags / pixels / codes within its platform.",
    text_integraciones_facebookPixel: "Facebook pixel",
    text_integraciones_facebookPixel_info:
      "Measure the conversions of our Facebook advertising campaigns and the cost of each of them, as we can configure the tracking by assigning a monetary value.",
    text_integraciones_facebookMessenger: "Facebook Messenger",
    text_integraciones_facebookMessenger_info:
      "Add a chat to your website with Messenger. With Messenger chat, you can provide personalized help.",
    text_integraciones_miPaquete: "Mi paquete",
    text_integraciones_miPaquete_info:
      "Traditional shipping, cash on delivery, tracking and automation, for delivery of your online store sales.",
    text_integraciones_rocketfy: "Rocketfy",
    text_integraciones_rocketfy_info:
      "Ship nationwide and charge cash on delivery or digital. If you have your own couriers, you can assign shipments to them.",
    text_integraciones_flow: "Flow",
    text_integraciones_flow_info:
      "Flow is an online payment platform where you can pay and receive payments quickly and securely.",
    text_integraciones_cubiko: "Cubiko",
    text_integraciones_cubiko_info:
      "Manage your products and advertise them in different channels in a fast and easy way.",
    text_integraciones_paymentsWay: "PaymentsWay",
    text_integraciones_paymentsWay_info:
      "FinTech provides payment solutions through electronic payment gateway services.",
    text_integraciones_googleMerchat:
      "HTML tag generated by google to verify Google services, You need this verification to claim the website and get approval for their products. The tag acts as a marker to help Google identify your website and does not provide access or permissions to your website.",
    //Mi perfil
    text_perfil_miPerfil: "😃 My Profile",
    text_perfil_ajustes: "Settings",
    text_perfil_referidos: "Referrals",
    text_perfil_suscription: "Subscription",
    text_perfil_facturacion: "Billing",
    text_perfil_contraseña: "Password",
    text_perfil_admin: "Management",
    text_perfil_users: "Users",
    text_perfil_informacionPerfil: "Profile Information",
    text_perfil_nombreApellido: "First and Last Name",
    text_perfil_correoElectronico: "Email Address",
    text_perfil_departamento: "Department",
    text_perfil_ciudad: "City",
    text_perfil_telefono: "Phone",
    text_perfil_direccion: "Address",
    text_perfil_tipoIdentificacion: "Type of identification",
    text_perfil_numeroIdentificacion: "ID Number",
    text_perfil_cambiarContraseña: "Change Password",
    text_perfil_cambiarEmail: "Change email Address",
    text_perfil_contraseñaActuaal: "Your current password",
    text_perfil_nuevaContraseña: "Your new password",
    text_perfil_repiteContraseña: "Repeat your new password",
    //Etiquetas
    text_etiquetas_tittleEtiquetas: "Tag",
    text_etiquetas_selectEtiquetas: "Select a tag",
    text_etiquetas_sinEtiquetas: "No tag",
    text_etiquetas_grupoEtiquetas: "Tag group",
    text_etiquetas_tagsBtn: "New tag",
    text_etiquetas_posicion: "Position",
    text_etiquetas_notEtiqueta: "No tag added",
    //referidos
    //suscripcion
    //facturacion
    text_perfil_configuracionFacturacion: "Electronic invoicing configuration",
    text_perfil_tipo: "Type",
    text_perfil_numeroDocumento: "Document number",
    text_perfil_regimen: "Regime",
    text_perfil_codigoImpuestos: "Tax level code",
    text_perfil_primerNombre: "First Name",
    text_perfil_apellido: "Last name",
    //footer
    text_footer_derechosReservados: "All rights reserved",
    text_footer_creadoCon: "Created with",
    text_footer_enColombia: "in latam.",
    text_footer_lenguaje: "Language: ",
    text_footer_pais: "Country: ",
    text_footer_moneda: "Currency: ",
    text_footer_idoma: "Store language: ",
    text_footer_siguenos: "Follow us: ",
    // header
    text_header_pendiente: "Pending",
    text_header_refiereamigo: "or refer a friend",
    text_header_publicidad:
      "Win! 📣 By recommending Komercia, give free months and get free months for life ✌🏻💯",
    text_header_publicidadPrueba1: "You have",
    text_header_publicidadPrueba2: "trial day",
    text_header_publicidadPrueba3: "trial days ✌🏻💪🏻",
    text_header_publicidadPrueba4:
      "days of subscription. If you have automatic payment do not worry, keep boosting your sales. 🚀",
    text_header_publicidadPrueba5:
      "day of subscription. If you have automatic payment do not worry, keep boosting your sales. 🚀",
    text_header_btnrefer: "Refer now",
    text_header_btnrePubli: "Subscribe now",
    text_header_howrefer: "More information here",
    text_header_emprendedor: "Entrepreneur",
    text_header_buscador: "Search",
    text_header_inventario: "Select your inventory",
    text_header_verTienda: "View store",
    text_header_verPage: "Preview",
    text_header_cerrarSesion: "Logout",
    text_header_loNuevo: "What´s New",
    text_header_apagarTienda: "Shut down store",
    //hoko
    text_hoko_btn: "Select",
    text_hoko_activar: "Activate",
    text_hoko_quitar: "Remove",
    text_hoko_title: "Select your inventory type",
    text_hoko_title_text_1: "Own inventory",
    text_hoko_info1:
      "The online store´s inventory is responsible for stocking inventory, placing orders and managing the shipping of orders.",
    text_hoko_title_text_2: "Hoko Inventory",
    text_hoko_info2:
      "HOKO allows you access to hundreds of suppliers throughout the country with thousands of products and hundreds of thousands of units ready for sale and shipment, in addition you can have private stocks of the most winning products or your exclusive suppliers.",
    //Importar / Exportar
    text_paymentBlock_title_exportar: "Export products",
    text_paymentBlock_descrip_exportar:
      "Download a list of your own products in Komercia. You can download it in .xls format",
    text_client_descrip_exportar:
      "Download a list of your own customers in Komercia. You can download it in .xls format",
    text_paymentBlock_textbtn_exportar: "Export .xls file",
    text_paymentBlock_title_importar: "Import products",
    text_paymentBlock_descrip_importar:
      "The allowed format is .xlsx or csv which is a special extension of spreadsheets such as Excel.",
    text_paymentBlock_textbtn_importar: "Import .xls file",
    text_paymentBlock_advertencia: "Warning!",
    text_paymentBlock_text_advertencia:
      "You have exceeded the product limit of your plan, you must renew your membership.",
    text_paymentBlock_renovar: "Renew membership",
    text_paymentBlock_brear_text1: "Back > Import / Export >",
    text_paymentBlock_brear_text2: "Import file",
    text_paymentBlock_confirmar: "Confirm",
    // estadisticas
    text_statistics_tittle: "Statistics",
    // etiqueta
    text_etiquetas_title: "Assign tag",
    text_etiquetas_newTag: "New tag",
    text_etiquetas_assingedTag: "Assigned tags",
    //helpDesk
    text_helpDesk_Aprende: "Learn more",
    text_helpDesk_diseño: "Design",
  },
  pt: {
    //paymentExpired
    text_payment_expired_title: "Sua assinatura expirou",
    text_payment_expired_description:
      "Alguns recursos são bloqueados, renove sua assinatura para acessar todos os conteúdos e serviços.",
    text_payment_expired_btn: "Minha Assinatura",
    text_payment_limitProducts: "Você excedeu o limite do produto",
    //menu
    text_nosesion_title: "Entrar novamente",
    text_nosesion_subtitle:
      "Sua sessão foi encerrada devido à inatividade. Você deve entrar novamente para acessar o painel do administrador de sua loja.",
    text_nosesion_iniciar: "Faça o login",
    text_inicio: "Início",
    text_inventario: "Inventário",
    text_ventas: "Vendas",
    text_analytics: "Analytics",
    text_estadisticas: "📊 Estatísticas",
    text_analyticsProduct: "📦 Produtos",
    text_tienda_online: "Loja on-line",
    text_whatsapp: "WhatsApp",
    text_academia: "Academia",
    text_LinkKomercia: "Link Komercia",
    text_empresa: "Empresa",
    text_regalo: "Prêmios",
    text_integraciones: "Integrações",
    text_mi_perfil: "Meu perfil",
    text_ayuda: "Ajuda",
    text_salir: "Saída",
    text_inventario2: "📦 Inventário",
    text_categorias: "Categorias",
    text_tags: "Etiquetas",
    text_papelera: "Lixo",
    text_guardaCambios: "Salvar mudanças",
    text_guardadoCorrectamente: "Salvando com sucesso",
    //HelpDesk
    text_helpDesk: "HelpDesk",
    text_helpDesk2: "📎 HelpDesk",
    text_helpDesk_listado: "Artigos",
    text_helpDesk_editor: "Modelo do editor",
    text_helpDesk_congfiguracion: "Configuração",
    //Inicio
    text_inicio_visitar: "Visitas",
    text_inicio_totales: "totais",
    text_inicio_productos: "Produtos",
    text_inicio_listDropshipping: "Produtos de dropshipping",
    text_inicio_publicados: "publicado",
    text_inicio_ventas: "Vendas",
    text_inicio_generadas: "gerado",
    text_inicio_valor: "Valor",
    text_inicio_total: "total",
    text_inicio_ingresos: "Renda",
    text_inicio_nuevaIntegracion: "Nova integração",
    text_inicio_facebook_info:
      "Ajude os clientes a navegar em seu site com o plugin gratuito de bate-papo do Facebook para obter vendas.",
    text_inicio_capacitacion: "Saiba mais sobre",
    text_inicio_capacitacion2:
      "e as suas características, tire o máximo partido do seu plano e melhore a sua loja online connosco.",
    text_inicio_conectar: "Conecte-se a",
    text_inicio_participar: "Quero participar",
    text_inicio_cursoRecomendados: "Cursos recomendados",
    text_inicio_ver_cursoRecomendados: "Ver todos os cursos",
    text_inicio_videosRecomendados: "Vídeos recomendados",
    text_inicio_ver_videosRecomendados: "Ver todos os vídeos",
    text_inicio_video_info1:
      "Gerenciamento de embarque de produtos - Envioclick Alliance",
    text_inicio_video_info2:
      "Transformação digital em empreendedores de Big San",
    text_inicio_video_info3: "Catálogo de WhatsApp",
    text_inicio_video_info4: "Komercia Chile",
    text_inicio_video_info5:
      "Aprenda como criar sua loja on-line do zero on-line",
    text_inicio_video_info6:
      "Marketing digital de desembarque com Luis Betancourt",
    text_inicio_video_info7: "Curso básico de fotografia de produtos",
    text_inicio_video_info8: "Facebook e Instagram Ads curso",
    text_inicio_blogRecomendados: "blogs",
    text_inicio_ver_blogRecomendados: "Ver todos os artigos",
    text_inicio_blog_info1:
      "Guia completo para habilitar as compras Instagram com sua loja on-line",
    text_inicio_blog_info2:
      "Quais são os tamanhos de banner das lojas online criadas na Komercia.co?",
    text_inicio_blog_info3: "Integre o Pixel do Facebook em sua loja online",
    text_inicio_blog_info4: "Integração com o Facebook Messenger",
    text_inicio_hola: "Olá, ",
    text_inicio_tuTiendaOnline: "Sua loja online:",
    text_inicio_tuTiendaOnlineEstaCerrada:
      "Sua loja está fechada e não pode receber vendas",
    text_inicio_abrirTienda: "Loja aberta",
    //Inventario
    text_inventario_1: "Integração com o Facebook Messenger",
    text_inventario_duplicar: "Duplicata",
    text_inventario_ver: " Ver",
    text_inventario_aprendeMas: "Saiba mais sobre ",
    //categorias
    text_inventario_probar: "Teste",
    text_inventario_editar: "Editar",
    text_inventario_elimnar: "Excluir",
    text_inventario_previsualizar: "Pré-visualização",
    text_inventario_restablecar: "Reinicialização",
    text_inventario_nombre: "Nome",
    text_inventario_numeroProductos: "Número de produtos",
    text_inventario_copiarUrl: "Copiar URL",
    text_inventario_escribeCategoria: "Digite a categoria",
    text_inventario_urlCategoriaCopiada: "categoria de cópia url!",
    text_inventario_copiar: "Cópia",
    text_inventario_escribeSubCategoria: "Digite a subcategoria",
    text_inventario_noHayCategorias: "Sem categorias",
    text_inventario_agregarCategoriaLista: "Acrescentar uma categoria à lista",
    text_inventario_NuevaCategoria: "Nova categoria",
    text_inventario_subNuevaCategoria: "Nova subcategoria",
    text_inventario_editarCategoria: "Categoria de edição",
    text_inventario_editarSubCategoria: "Editar subcategoria",
    text_inventario_agregarSubCategoriaLista:
      "Acrescenta uma nova subcategoria à lista",
    text_inventario_seleccionaCategoria: "Selecione a categoria",
    text_inventario_seleccionaSubCategoria: "Selecione a subcategoria",
    text_inventario_aprendeSobreCategorias: "Saiba mais sobre as categorias",
    //papelera
    text_inventario_papelera: "Lata de lixo",
    text_inventario_tienes: "Você tem",
    text_inventario_prodcuctosQueNoEstanPublicados:
      "produtos que não são publicados em sua loja",
    text_inventario_yaSuperaste: "Você já excedeu a",
    text_inventario_actualizaTuPLan: "produtos, atualize seu plano",
    text_inventario_eliminarSeleccionados: "Eliminar produtos selecionados",
    text_inventario_publicarSeleccionados: "Publicar produtos selecionados",
    text_inventario_publicar: "Publicar",
    text_inventario_id: "Id",
    text_inventario_foto: "Foto",
    //productos
    text_inventario_cancelar: "Cancelar",
    text_inventario_guardar: "Salvar",
    text_inventario_cargando: "Carregando...",
    text_inventario_fotoProducto: "Imagem do produto",
    text_inventario_subirOtraImagen: "Carregar outra imagem",
    text_inventario_videoProducto: "Vídeo do produto (opcional)",
    text_inventario_URLvideoYoutube: "URL do vídeo do Youtube",
    text_inventario_lisdatoProducto: "Listagem de produtos",
    text_inventario_productoN: "N° do produto",
    text_inventario_organizar: "Organize",
    text_inventario_textCrearOrganizaProductos:
      "Crie categorias e organize os produtos em sua loja para facilitar a localização de seus clientes.",
    text_inventario_filtrar: "Filtro",
    text_inventario_textFiltrar:
      "Crie etiquetas para filtrar os produtos em sua loja a fim de facilitar a localização de seus clientes.",
    text_inventario_subCategorias: "Subcategorias",
    text_inventario_sinCategorias: "Nenhuma categoria",
    text_inventario_sinSubCategorias: "Sem subcategoria",
    text_inventario_favoritos: "Favoritos",
    text_inventario_peso: "Peso",
    text_inventario_largo: "Comprimento",
    text_inventario_ancho: "Largura",
    text_inventario_alto: "Alto",
    text_inventario_garantia: "Garantia",
    text_inventario_garantiaProducto: "Garantia de produto",
    text_inventario_marca: "Marca",
    text_inventario_palabrasClave: "Palavras-chave",
    text_inventario_añadirFavoritos: "⭐ Adicionar aos favoritos",
    text_inventario_añadidoFavoritos: "⭐ Adicionado aos favoritos",
    text_inventario_envioGratis: "Frete grátis",
    text_inventario_recomendado: "Recomendado",
    text_inventario_productosConEnviosGratis:
      "produtos com frete gratuito aumentam suas chances de venda",
    text_inventario_no: "Não",
    text_inventario_si: "Sim",
    text_inventario_descriptionCorta: "Breve descrição (140 caracteres)",
    text_inventario_descriptionCortaMSG: "Breve descrição",
    text_inventario_requerido: "Obrigatório",
    text_inventario_description: "Descrição",
    text_inventario_opcional: "Opcional",
    text_inventario_priopridadAtienda: "Prioridade de comparência na loja",
    text_inventario_priopridadAtiendaMSG:
      "Os produtos com alta prioridade (10) aparecerão nas primeiras posições em sua loja.",
    text_inventario_deseaAplicarDescuento:
      "Você quer aplicar um desconto simulado ao produto?",
    text_inventario_deseaAplicarDescuentoreal:
      "Você deseja aplicar um desconto real ao produto?",
    text_inventario_ValorPorcentaje: "Porcentagem do valor",
    text_inventario_nota: "Nota: ",
    text_inventario_notaMsg:
      "O valor percentual não deve atingir 100%, máximo 99%.",
    text_inventario_escribirMensajeProducto:
      "Escreva uma mensagem junto a este produto?",
    text_inventario_escribirMensajeProductoMSG:
      "É obrigatório que o comprador preencha a mensagem?",
    text_inventario_etiquetaInstrucciones: "Rótulo/Instruções para a mensagem",
    text_inventario_productoVariantes: "Produto sem variantes",
    text_inventario_referencia: "Referência (opcional)",
    text_inventario_SKU: "SKU",
    text_inventario_infoVariantes:
      "As variantes são características adicionais do produto.",
    text_inventario_porEjemplo: "Por exemplo:",
    text_inventario_infoVariantes2:
      "tamanho, material ou cor, que lhe permitem organizar seu estoque.",
    text_inventario_crearVariantes: "Criar variantes",
    text_inventario_infoVariantes3:
      "Adicione variantes se este produto vier em múltiplas versões, tais como tamanhos ou cores diferentes.",
    text_inventario_nombreOpcion: "Nome da opção",
    text_inventario_ValoresOpcion: "Valores das opções",
    text_inventario_escribeOpcion: "Digite a opção",
    text_inventario_cambiarNombre:
      "Mudar o nome. Remover espaços no início e no final",
    text_inventario_agregar: "Adicione",
    text_inventario_agregarVariantes: "Adicionar variante",
    text_inventario_modificarVariantes:
      "Modificar as variantes a serem criadas:",
    text_inventario_variantes: "Variante",
    text_inventario_referencia2: "Referência",
    text_inventario_mejoraTuPlan: "Melhore seu plano agora",
    text_inventario_generarDescription: "Geração de descrição",
    text_inventario_generandoDescription: "Geração de descrição",
    text_inventario_inteligenciaArtificial: "Inteligência artificial",
    text_inventario_inteligenciaArtificial_description:
      "Para gerar uma descrição de produto usando inteligência artificial, você deve inserir algumas palavras-chave.",
    text_inventario_palabrasClaves: "Palavras-chave",
    text_inventario_seleccionarIdioma: "Selecione o idioma",
    //Ventas
    text_ventas_buscarProducto: "Buscar produto",
    text_ventas_todos: "Todos",
    text_ventas_exportar: "Exportação",
    text_ventas_importar: "Importação",
    text_ventas_ver_productos: "Ver produtos",
    text_ventas_editar_productos: "Editar produtos",
    text_ventas_agotados: "Fora de estoque",
    text_ventas_nuevo_producto: "Novo produto",
    text_ventas_nombre_producto: "Nome do produto",
    text_ventas_combinacion: "Combinação",
    text_ventas_unidades: "Unidades",
    text_ventas_precio: "Preço",
    text_ventas_accciones: "Ações",
    text_ventas_agotado: "ESGOTADO",
    text_ventas_producto_variantes: "Produto com variantes",
    text_ventas_variantes_desactivas: "Variantes desativadas",
    text_ventas_state_sales: "Status de vendas",
    text_ventas_state_shipment: "Status do embarque",
    //menu
    text_ventas_title: "💰 Vendas",
    text_ventas_listado: "Lista de vendas",
    text_ventas_listado_hoko: "Listagem Hoko",
    text_ventas_ventaRapida: "Venda rápida",
    text_ventas_clientes: "Clientes",
    text_ventas_clientes2: "👤 Clientes",
    //tablet
    text_ventas_misVentas: "Minhas vendas",
    text_ventas_misVentas_hoko: "Minhas vendas Hoko",
    text_ventas_filtrarNOrden: "Filtrar por número de ordem",
    text_ventas_BUscarNOrden: "Procurar por Nº de ordem",
    text_ventas_filtrarEstado: "Filtrar por status",
    text_ventas_filtrarMethodPay: "Filtrar por método de pagamento",
    text_ventas_seleccionaEstado: "Selecione um status",
    text_ventas_filtrarFecha: "Filtrar por data",
    text_ventas_seleccionaFecha: "Selecione uma data",
    text_ventas_filtrarCliente: "Filtrar por cliente",
    text_ventas_filtrarID: "Filtrar por ID",
    text_ventas_seleccionaCliente: "Selecione um cliente",
    text_ventas_exportarVentas: "Vendas para exportação",
    text_ventas_visibilidad: "Visível",
    text_ventas_publico: "Público",
    text_ventas_privado: "Particular",
    text_ventas_estado: "Status",
    text_ventas_fecha: "Date",
    text_ventas_medio: "Médio",
    text_ventas_envio: "Enviar para",
    text_ventas_transportadora: "Transportador",
    text_ventas_cliente: "Cliente",
    text_ventas_ID: "Identificação",
    text_ventas_copiado: "Copiado!",
    text_ventas_copiarLink: "Copiar link",
    text_ventas_Ver: "Ver",
    text_ventas_sinPrecios: "Sem preços",
    text_ventas_asignar: "Atribuir",
    text_ventas_sinPagar: "Não pago",
    text_ventas_pagada: "Pago",
    text_ventas_rechazada: "Declinado",
    text_ventas_cancelado: "Cancelado",
    text_ventas_despachado: "Despachado",
    text_ventas_recibido: "Recebido",
    text_ventas_entregado: "Entregue em",
    text_ventas_finalizado: "Concluído",
    text_ventas_cotizacion: "Citação",
    text_ventas_abandonada: "Abandonado",
    text_ventas_retenida: "Retenção",
    text_ventas_reversada: "Invertido",
    text_ventas_devuelto: "Devolvido",
    text_ventas_enTransito: "Em trânsito",
    text_ventas_enEmpaque: "Na embalagem",
    text_ventas_procesandoPago: "Processamento de pagamentos",
    text_ventas_exportarMisVentas: "Exportar minhas vendas",
    text_ventas_descargar: "Download ",
    text_ventas_infoDescargar:
      "uma lista de suas vendas. Você pode baixá-la nestes formatos XLS , CSV",
    text_ventas_exportarXLS: "Exportação em XLS",
    text_ventas_exportarCSV: "Exportação em CSV",
    //pagos
    text_pagos_epaycoTC: "ePayco TC",
    text_pagos_epaycoPSE: "ePayco PSE",
    text_pagos_epaycoEfectivo: "ePayco efectivo",
    text_pagos_consignación: "Consignação",
    text_pagos_efecty: "Efecty",
    text_pagos_pagaTienda: "Pagar na loja",
    text_pagos_pagaConvernir: "Pagamento sob solicitação",
    text_pagos_epaycoSafetyPay: "ePayco SafetyPay",
    text_pagos_pagaContraentrega: "Contra-reembolso",
    text_pagos_mercadopagoTC: "Mercadopago TC",
    text_pagos_payU: "Payu",
    text_pagos_mercadoPAgo: "MercadoPago",
    text_pagos_ePayco: "ePayco",
    text_pagos_payUTUC: "Payu TC",
    text_pagos_payUEfectivo: "Payu efectivo",
    text_pagos_payUPSE: "Payu PSE",
    text_pagos_mercadopagoPSE: "Mercadopago PSE",
    text_pagos_daviplata: "Daviplata",
    text_pagos_nequi: "Nequi",
    text_pagos_Wompi: "Wompi",
    text_pagos_FLOW: "FLOW",
    text_pagos_TUCOMPRA: "TuCompra",
    text_pagos_wepay4U: "Wepay4U",
    text_pagos_addi: "Addi",
    text_pagos_Hoko: "HOKO",
    text_pagos_Hoko_recaudo: "HOKO (Cobrança em dinheiro)",
    //TiendaOnline
    text_tiendaOnline_title: "🛒 Loja on-line",
    text_tiendaOnline_cupones: "Cupões",
    text_tiendaOnline_descuentos: "Descontos",
    text_tiendaOnline_buzon: "Caixa de correio",
    text_tiendaOnline_suscriptores: "Assinantes",
    text_tiendaOnline_seguridadEditor: "Editor de segurança",
    text_tiendaOnline_controlDeAcceso: "Controle de acesso",
    text_tiendaOnline_usarPlantillaEditor: "Use o modelo do editor",
    text_tiendaOnline_actual: "Atual",
    text_tiendaOnline_infoEditorViejo:
      "O editor de design permite modificar seu modelo e configurar alguns componentes a seu gosto.",
    text_tiendaOnline_usar: "Use",
    text_tiendaOnline_demo: "Demonstração",
    text_tiendaOnline_reset: "Projeto de restauração",
    text_tiendaOnline_usoDiseñoActual:
      "Este é o design que você está usando atualmente em sua loja.",
    text_tiendaOnline_personalizarDiseño: "🎨 ersonalize o design",
    text_tiendaOnline_verMitienda: "Ver minha loja",
    text_tiendaOnline_plantillaExclusiva: "Modelo exclusivo ",
    text_tiendaOnline_usuariosPremium: "para usuários Premium",
    text_tiendaOnline_quieroPremium: "😃 Eu quero ser Premium!",
    text_tiendaOnline_verDemostracion: "Ver demonstração",
    text_tiendaOnline_usarPlantilla: "Use este modelo",
    text_tiendaOnline_disponiblePronto: "Disponível em breve",
    text_tiendaOnline_usandoPlantilla: "👋 Você está usando este modelo",
    text_tiendaOnline_premium: "🏆 Premium",
    text_tiendaOnline_enActualizacion: "⌛ Em atualização",
    text_tiendaOnline_nueva: "⭐ Novo",
    text_tiendaOnline_endesarrolloPronto: "⌛ Em desenvolvimento, em breve!",
    // comunidad
    text_comunidad: "📚 Comunidade",
    text_comunidad_partners: "Partners",
    text_comunidad_vacantes: "Ofertas de emprego",
    text_comunidad_Perks: "Perks",
    text_comunidad_cupones: "Cupões",
    text_comunidad_Awards: "Premios",
    //cupones
    text_cupones_title: "Cupons de Desconto",
    text_cupones_subtitle:
      "Crie códigos de desconto para compartilhar com seus usuários.",
    text_cupones_crearCupon: "Criar cupom",
    text_cupones_modificarCupon: "Modificar o cupom",
    text_cupones_nombreCupon: "Nome do cupom",
    text_cupones_valor: "valor",
    text_cupones_porcentaje: "porcentagem",
    text_cupones_codigo: "Código",
    text_cupones_actualizar: "Atualização",
    text_cupones_cerrarVentana: "Fechar janela",
    text_cupones_codigoCupon: "Código do cupom",
    text_cupones_nombreCupoin: "Nome do cupom",
    text_cupones_valorDescuento: "Valor do desconto",
    text_cupones_porValor: "Por valor",
    text_cupones_creacion: "Criação",
    text_cupones_activo: "ATIVO",
    text_cupones_inactivo: "INATIVO",
    //descuentos
    text_descuentos_crearDescuento: "Criar desconto",
    text_descuentos_editarDescuento: "Editar desconto",
    text_descuentos_cantidadProductos: "Quantidade de produtos",
    text_descuentos_crearDescuentoNuevo: "Criar novo desconto",
    text_descuentos_descuento: "Desconto ",
    text_tiendaOnline_descuentos_tittle:
      "Criar descontos para aplicar às compras.",
    //blog
    text_blog_info:
      "Em Komercia você pode criar seu blog para sua loja online.",
    text_blog_buscarBlog: "Buscar blog",
    text_blog_crearNblog: "CRIAR UM NOVO BLOG",
    text_blog_publicado: "Publicado em",
    text_blog_editando: "Editando",
    text_blog_listadoArticulos: "Lista de artigos",
    text_blog_editarArticulos: "Editar artigo ",
    text_blog_portada: "Página de rosto",
    text_blog_subirImagen: "Carregar imagem",
    text_blog_tamañoSugerido: "Tamanho sugerido 1500 x 652 pixels",
    text_blog_eliminar: "Remover artigo",
    text_blog_titulo: "Título",
    text_blog_faltaTitulo: "Título do artigo em falta",
    text_blog_autor: "Autor",
    text_blog_faltaAutor: "Autor do artigo em falta",
    text_blog_resumen: "Abstrato",
    text_blog_faltaResumen: "Resumo do artigo em falta",
    text_blog_contenido: "Conteúdo",
    text_blog_visible: "Visível",
    text_blog_oculto: "Escondido",
    text_blog_crearArticulo: "Criar artigo",
    //buzon
    text__buzon_title: "Caixa de mensagens",
    text__buzon_subtitle:
      "Estas são as mensagens que seus clientes escreveram para você na seção de contato de sua loja",
    text__buzon_buscarCliente: "Busca de clientes",
    text__buzon_fechaEnvio: "Data de envio:",
    text__buzon_eMail: "Email:",
    text__buzon_telefono: "Telefone:",
    //suscriptores
    text_suscriptores_title: "Meus assinantes",
    text_suscriptores_fechaSuscripcion: "Data da Assinatura",
    //cubiko
    text_cubiko_text1: "gerenciar seus ",
    text_cubiko_text2: "produtos",
    text_cubiko_text3: " e",
    text_cubiko_text4: "anunciá-los",
    text_cubiko_text5: " em diferentes canais de maneira fácil e rápida.",
    text_cubiko_abrirCubiko: "Painel cubiko aberto",
    text_cubiko_desconectarIntegracion: "Desconectar a integração",
    text_cubiko_iniciaFacebbok: "Login com Facebook",
    //Whatsapp
    text_whatsapp_title: "👍 Loja WhatsApp",
    text_whatsapp_diseño: "Modelos",
    text_whatsapp_diseño2: "🎨 Modelos",
    text_whatsapp_diseño3: "Modelos disponíveis",
    text_whatsapp_diseño4: "Meus sites",
    text_whatsapp_configuracion: "Configuração",
    text_whatsapp_checkout_dynamic: "Checkout dinâmico",
    text_whatsapp_catalogoWhatsApp: " Catálogo WhatsApp",
    text_whatsapp_catalogoWhatsApp_info:
      "Compartilhe seu link de catálogo e receba os pedidos diretamente como uma mensagem WhatsApp.",
    text_whatsapp_personalizaDiseño: "Personalize o design",
    text_whatsapp_imagenPortada: "Imagem da capa",
    text_whatsapp_tamañoRecomendado900x300:
      "Tamanho recomendado: 900px x 300px",
    text_whatsapp_cambiar: " Mudança",
    text_whatsapp_logo: "Logotipo",
    text_whatsapp_QRtienda: "Loja QR",
    text_whatsapp_fondoLogo: "Histórico do logotipo",
    text_whatsapp_mensajeTienda: "Mensagem da loja",
    text_whatsapp_descripcion: "Descrição",
    text_whatsapp_descripcionTiendaWapp: "Descrição da loja Whatsapp",
    text_whatsapp_temaTienda: "Tema da Loja",
    text_whatsapp_tema1: "Tema 1",
    text_whatsapp_tema2: "Tema 2",
    text_whatsapp_tema3: "Tema 3",
    text_whatsapp_estiloCategorias: "Estilo de categoria",
    text_whatsapp_opcion1: "Opção1",
    text_whatsapp_opcion2: "Opção2",
    text_whatsapp_opcion3: "Opção3",
    text_whatsapp_opcion4: "Opção4 (Img)",
    text_whatsapp_subcategory: "Habilitar subcategoria",
    text_whatsapp_estilosProductos: "Estilo de produtos",
    text_whatsapp_botonOnline: "Botão de pagamento on-line",
    text_whatsapp_watermark: "Marca d´água (Komercia)",
    text_whatsapp_colorBotones: "Cor dos botões",
    text_whatsapp_colorTextoBotones: "Cor do texto dos botões",
    text_whatsapp_colorDegradadoBanner: "Banner de gradiente de cor (Tema 3)",
    text_whatsapp_cuadrado: "Quadrado",
    text_whatsapp_redondo: "Rodada",
    text_whatsapp_lista: "Lista",
    text_whatsapp_grid: "Grade",
    text_whatsapp_conectaRedeSociales: "Conecte suas redes sociais",
    text_desing_add: "Criar site",
    text_desing_edit: "Editar site",
    text_desing_delete: "Excluir site",
    //Academia
    //home
    text_empresa_congfiguracion: "👋 Configuração",
    text_empresa_miEmpresa: "Minha empresa",
    text_empresa_redes: "Redes",
    text_empresa_pagos: "Pagamentos",
    text_empresa_envios: "Expedição",
    text_empresa_sedes: "Locais",
    text_empresa_legal: "Legal",
    //Empresa
    text_empresa_idTienda: "ID da loja",
    text_empresa_nombreTienda: "Nome da empresa",
    text_empresa_idioma: "Idioma",
    text_empresa_idiomaTienda: "Selecione o idioma da loja",
    text_empresa_moneda: "Moeda",
    text_empresa_seleccionaModenaTienda: "Selecione a moeda da loja",
    text_empresa_pais: "País",
    text_empresa_seleccionaPais: "Selecione o país",
    text_empresa_departamento: "Departamento",
    text_empresa_estado: "Estado",
    text_empresa_region: "Região",
    text_empresa_municipios: "Municípios",
    text_empresa_rexones: "Rexones",
    text_empresa_provincia: "Província",
    text_empresa_distrito: "Distrito",
    text_empresa_categoria: "Categoria",
    text_empresa_palabrasClaves: "Palavras-chave",
    text_empresa_ropaCalzado: "roupas, calçados",
    text_empresa_valorMinimoCompra: "Valor mínimo de compra",
    text_empresa_ventaMinima: "Valor mínimo de compra",
    text_empresa_descripcionTienda: "Descrição da loja",
    text_empresa_agregarFotografia: "Adicione uma foto do seu negócio",
    text_empresa_subdominio: "Subdomínio",
    text_empresa_dominio: "domínio",
    text_empresa_tuDominio: "Seu subdomínio foi criado",
    text_empresa_necesitasAyuda: "Precisa de ajuda?",
    text_empresa_agregarIntegracionesOnline:
      "Adicione as melhores integrações à sua loja online",
    text_empresa_irIntegraciones: "Ir para integrações",
    text_empresa_regimen: "Regimen",
    text_empresa_nit: "NIT",
    //pago
    text_pago_title: "Configuração das opções de pagamento",
    text_pago_subTitle:
      "Selecione seu gateway de pagamento preferido e métodos de pagamento.",
    text_meth_pago_subTitle:
      "Seleccione os seus métodos de pagamento para a sua loja online",
    text_opc_pagos: "Portas de pagamento",
    text_pago_politicasPago: "Políticas de pagamento",
    text_pago_metodosPago: "Formas de pagamento",
    text_pago_politicasInfo:
      "Utilize este espaço para definir as políticas, condições e/ou restrições associadas aos métodos de pagamento que você escolheu.",
    text_pago_epaycoInfo:
      "Aceite pagamentos on-line de sua loja on-line, ofereça múltiplos métodos de pagamento e formas de pagamento. Seus clientes poderão comprar seus produtos de forma mais segura, fácil e rápida.",
    text_pago_camposObligatorios: "odos os campos são obrigatórios",
    text_pago_integracionPagosOnline: "Integração de pagamentos on-line",
    text_pago_identificacionEpayco: "Identificação do epayco",
    text_pago_calculadoraTranssacciones: "Calculadora de transações",
    text_pago_simuladorTransacciones: "Simulador de transação",
    text_pago_mercadoLibreInfo:
      "Com a tecnologia mercadolibre, integramos seu bem sucedido gateway de pagamento Mercadopago que lhe permite receber pagamentos em 15 países da América Latina.",
    text_pago_mercadoLibreConecta: "Conecte-se ao Mercado Pago",
    text_pago_mercadoLibreYaConecta: "Você já está ligado à Mercadopago com",
    text_pago_calculaCostos: "Calcule seus custos",
    text_pago_credibancoInfo:
      "Leve seus negócios ao mundo digital, aumente suas vendas e alcance mais clientes, encontre a solução que atenda às suas necessidades e ofereça mais opções de pagamento on-line.",
    text_pago_wompiInfo:
      "Empresa do Grupo Bancolombia que fornece serviços de gateway de pagamento para indivíduos, pequenas e grandes empresas.",
    text_pago_paymentWay:
      "Empresa da FinTech que fornece soluções de pagamento através de serviços de gateway para cobrança eletrônica, ecossistemas transacionais, aplicações móveis, comércio eletrônico, alças inteligentes e soluções personalizadas.",
    text_pago_verDocumentacion: "Veja a documentação.",
    text_pago_iniciarConfiguracion: "Iniciar a configuração",
    text_pago_flowInfo:
      "Flow é uma plataforma de pagamento on-line onde você pode pagar e receber pagamentos de forma rápida e segura:",
    text_pago_Tucompra:
      "Eles são a nova era de pagamentos na América Latina, criando soluções modernas, eficientes, seguras e rápidas de cobrança, totalmente competentes para acelerar seus negócios e abrir novos nichos de mercado, graças às novas tecnologias. Tu Compra, seu parceiro seguro na cobrança.",
    text_pago_WePay4U:
      "Com a tecnologia da Wepay4U integramos o gateway de pagamento PayCash que permite receber pagamentos em todo o país.",
    text_pago_metodos: "Métodos",
    text_pago_activar: "Ativar",
    text_pago_desactivar: "Desativar",
    //envios
    text_envios: "Expedição",
    text_envios_info:
      "Configure o método de envio para sua loja (selecione uma opção)",
    text_envios_politicasEnvios: "Configurar as políticas de embarque",
    text_envios_metodosEnvio: "Métodos de embarque",
    text_envios_sinEnvio: "Desativado",
    text_envios_sintarifa: "Sem taxa de envio",
    text_envios_enviosGratis: "Frete grátis",
    text_envios_tarifaPlana: "Taxa fixa",
    text_envios_porPrecio: "Por preço",
    text_envios_porCiudad: "Por cidade",
    text_envios_valorEnvio: "Valor do frete",
    text_envios_para: "Para",
    text_envios_hasta: "Hasta",
    text_envios_agregarRango: "Adicionar nova linha",
    text_envios_escribeCiudad: "Digite a cidade",
    text_envios_restoPais: "O resto do país",
    text_envios_tiempoEntrega: "Prazo de entrega",
    text_envios_dias: "1 días",
    text_envios_addCiudad: "Adicionar cidade",
    text_envios_tarifaPais: "Taxa para o resto do país",
    text_envios_tarifaPaisInfo:
      "Se você habilitar a tarifa do resto do país, ela será aplicada para o resto das cidades não especificadas.",
    text_envios_HabilitarRestoPais: "Habilitar o resto do país",
    text_optional: "Opcional",
    text_envios_pagarOnline: "Recolher e pagar na loja",
    text_envios_pagarOnlineInfo1:
      "O comprador recolhe e paga pela sua compra no seu estabelecimento,",
    text_envios_pagarOnlineInfo2: "dessa forma o custo de envio não se aplica.",
    text_envios_politicasEnviosT: "Políticas de embarque",
    text_envios_politicasEnvioInfo:
      "Escreva claramente as condições para o processo de envio de seus produtos, cobertura da cidade, restrições de peso, tamanho e custos adicionais, tudo o que for necessário para que seu comprador resolva suas dúvidas antes de fazer a compra. Caso não especifique claramente todas as restrições, você deve manter o preço de envio e assumir os custos adicionais.",
    text_envios_tarifaporPrecio: "Taxa por preço",
    text_envios_tarifaporCiudad: "Taxa por cidade",
    text_envios_desc1:
      "Ofereça o envio de produtos a seus compradores como um presente. Encare isso como um investimento, você assume o valor do envio e melhora suas vendas. É altamente recomendável ter frete gratuito em sua loja para fidelizar seus clientes e aumentar suas vendas.",
    text_envios_desc2:
      "O valor do frete será sempre o mesmo, independentemente do número de itens, peso ou preço da compra. Haverá um valor fixo para as principais cidades e outro para o resto do país.",
    text_envios_desc3:
      "O valor do frete é calculado em função do preço da venda realizada. Ele cria dependendo do valor da venda e de um preço de envio. O sistema cobra automaticamente do comprador o valor do frete que você atribuiu a essa faixa.",
    text_envios_desc4:
      "O valor do frete é calculado de acordo com o preço da venda feita. Cria dependente do valor da venda e de um preço de remessa. O sistema cobra automaticamente do comprador o valor do frete que você atribuiu a essa faixa.",
    text_envios_desc5:
      "O valor da remessa será pago pelo cliente após o recebimento do pacote.",
    text_envios_desc6:
      "Desative a entrega em domicílio, o comprador retira e paga a compra em sua loja, dessa forma o custo de envio não se aplica.",
    //legal
    text_legal_info1: "Políticas de processamento de dados",
    text_legal_info2: "Política de garantia",
    text_legal_info3: "Política de Devolução / Retirada",
    text_legal_info4: "Política de intercâmbio",
    //Integraciones
    text_integraciones_title: "🚀 Integrações",
    text_integraciones_title2: "Integrações",
    text_integraciones_disponibles: "Integrações disponíveis",
    text_integraciones_masUsadas: "Integrações mais utilizadas",
    text_integraciones_actualizacion: "Atualização",
    text_integraciones_conectada: "😃 Conectado",
    text_integraciones_noConectada: "Não conectado",
    text_integraciones_noDisponible: "⛔ No disponible",
    text_integraciones_proximamente: "⏳ Próximamente",
    text_integraciones_seleccionarPais: "Seleccione el país",
    text_integraciones_seleccionarCategoria: "Seleccione la categoría",
    text_integraciones_disponibleColombia: "Disponible en Colombia",
    text_integraciones_disponibleChile: "Disponible en Chile",
    text_integraciones_disponiblePeru: "Disponible en Perú",
    text_integraciones_disponiblePuertoRico: "Disponible en Puerto Rico",
    text_integraciones_disponibleMexico: "Disponible en México",
    text_integraciones_disponibleEcuador: "Disponible en Ecuador",
    text_integraciones_disponibleArgentina: "Disponible en Argentina",
    text_integraciones_disponiblePanama: "Disponible en Panamá",
    text_integraciones_disponibleTodos: "Disponible para todos",
    text_integraciones_ePayco: "ePayco",
    text_integraciones_ePayco_info:
      "Acepte pagos en línea desde su tienda virtual, ofreciendo múltiples métodos y formas de pago. Tus clientes podrán comprar tus productos de forma más segura, fácil y rápida.",
    text_integraciones_payU: "PayU",
    text_integraciones_mercadoPago: "Mercado pago",
    text_integraciones_mercadoPago_info:
      "Solución de pago que permite a los vendedores recibir pagos de forma sencilla y rápida, entre tarjetas de crédito y débito.",
    text_integraciones_credibanCo: "CredibanCo",
    text_integraciones_credibanCo_info:
      "Lleva tu negocio al mundo digital, aumenta tus ventas y llega a más clientes, encuentra la solución que se adapte a tus necesidades y ofrece más opciones de pago online.",
    text_integraciones_mensajerosUrbanos: "Mensajeros urbanos",
    text_integraciones_mensajerosUrbanos_info:
      "Servicio de mensajería urbana. Te conectamos con el moto mensajero más cercano en Bogotá, Barranquilla, Cali, y otras ciudades de Colombia.",
    text_integraciones_envioClick: "EnvioClick",
    text_integraciones_envioClick_info:
      "Somos la primera plataforma de generación y gestión masiva de envíos que te conecta con servicios de paquetería y transporte de mercancías e integra tecnología innovadora, inteligencia artificial y un equipo de expertos para ofrecerte la logística de envíos más eficiente del mercado.",
    text_integraciones_wompi: "Wompi",
    text_integraciones_wompi_info:
      "Empresa del Grupo Bancolombia que presta servicios de pasarela de pagos a particulares, pequeñas y grandes empresas.",
    text_integraciones_googleAnalytics: "Google analytics",
    text_integraciones_googleAnalytics_info:
      "Herramienta de analítica web de Google que nos permite obtener información sobre el número de visitantes y el comportamiento en nuestra web.",
    text_integraciones_tidio: "Tidio",
    text_integraciones_tidio_info:
      "Chat online en vivo en tu tienda, con chatbots para obtener el mejor rendimiento cuando no estás disponible, para atender a los usuarios 24/7.",
    text_integraciones_googleManager: "Google Tag Manager",
    text_integraciones_googleManager_info:
      "GTM es un sistema de gestión o gestor de etiquetas que nos permite actualizar fácil y rápidamente todas aquellas etiquetas/píxeles/códigos dentro de su plataforma.",
    text_integraciones_facebookPixel: "Facebook pixel",
    text_integraciones_facebookPixel_info:
      "Mide las conversiones de nuestras campañas publicitarias en Facebook y el coste de cada una de ellas, ya que podemos configurar el seguimiento asignando un valor monetario.",
    text_integraciones_facebookMessenger: "Facebook Messenger",
    text_integraciones_facebookMessenger_info:
      "Añade un chat a tu sitio web con Messenger. Con el chat de Messenger, puedes proporcionar ayuda personalizada.",
    text_integraciones_miPaquete: "Mi paquete",
    text_integraciones_miPaquete_info:
      "Envío tradicional, contra reembolso, seguimiento y automatización, para la entrega de las ventas de tu tienda online.",
    text_integraciones_rocketfy: "Rocketfy",
    text_integraciones_rocketfy_info:
      "Envía a todo el país y cobra contra reembolso o en digital. Si tienes tus propios mensajeros, puedes asignarles los envíos.",
    text_integraciones_flow: "Flow",
    text_integraciones_flow_info:
      "Flow es una plataforma de pago online donde puedes pagar y recibir pagos de forma rápida y segura.",
    text_integraciones_cubiko: "Cubiko",
    text_integraciones_cubiko_info:
      "Gestiona tus productos y anúncialos en diferentes canales de forma rápida y sencilla.",
    text_integraciones_paymentsWay: "PaymentsWay",
    text_integraciones_paymentsWay_info:
      "FinTech ofrece soluciones de pago a través de servicios de pasarela de pago electrónico.",
    text_integraciones_googleMerchat:
      "Tag HTML gerada pelo google para verificar os serviços do Google, Você precisa desta verificação para reivindicar o site e obter aprovação para seus produtos. A tag age como um marcador para ajudar o Google a identificar seu website e não fornece acesso ou permissões para seu website.",
    //Mi perfil
    text_perfil_miPerfil: "😃 Meu perfil",
    text_perfil_ajustes: "Configurações",
    text_perfil_referidos: "Referências",
    text_perfil_suscription: "Assinatura",
    text_perfil_facturacion: "Faturamento",
    text_perfil_contraseña: "Senha",
    text_perfil_admin: "Administração",
    text_perfil_users: "Usuários",
    text_perfil_informacionPerfil: "Informações de perfil",
    text_perfil_nombreApellido: "Nome e sobrenome",
    text_perfil_correoElectronico: "Endereço de e-mail",
    text_perfil_departamento: "Departamento",
    text_perfil_ciudad: "Cidade",
    text_perfil_telefono: "Telefone",
    text_perfil_direccion: "Endereço",
    text_perfil_tipoIdentificacion: "Tipo de identificação",
    text_perfil_numeroIdentificacion: "Número de identificação",
    text_perfil_cambiarContraseña: "Mudança de senha",
    text_perfil_cambiarEmail: "Mudança Endereço de e-mail",
    text_perfil_contraseñaActuaal: "Sua senha atual",
    text_perfil_nuevaContraseña: "Sua nova senha",
    text_perfil_repiteContraseña: "Repita sua nova senha",
    //Etiquetas
    text_etiquetas_tittleEtiquetas: "Tag",
    text_etiquetas_selectEtiquetas: "Selecione uma etiqueta",
    text_etiquetas_sinEtiquetas: "Sem etiqueta",
    text_etiquetas_grupoEtiquetas: "Grupo de etiquetas",
    text_etiquetas_tagsBtn: "Nova etiqueta",
    text_etiquetas_posicion: "Posição",
    text_etiquetas_notEtiqueta: "Nenhuma etiqueta adicionada",
    //referidos
    //suscripcion
    //facturacion
    text_perfil_configuracionFacturacion:
      "Configuração de faturação eletrônica",
    text_perfil_tipo: "Tipo",
    text_perfil_numeroDocumento: "Número do documento",
    text_perfil_regimen: "Regime",
    text_perfil_codigoImpuestos: "Código de nível de imposto",
    text_perfil_primerNombre: "Primeiro nome",
    text_perfil_apellido: "Sobrenome",
    //footer
    text_footer_derechosReservados: "Todos os direitos reservados",
    text_footer_creadoCon: "Criado com",
    text_footer_enColombia: "na latam.",
    text_footer_lenguaje: "Idioma: ",
    text_footer_pais: "País: ",
    text_footer_moneda: "Moeda: ",
    text_footer_idoma: "Idioma da loja: ",
    text_footer_siguenos: "Siga-nos: ",
    // header
    text_header_pendiente: "Pendente",
    text_header_refiereamigo: "ou indique um amigo",
    text_header_publicidad:
      "Ganhe! 📣 Ao recomendar a Komercia, dê meses grátis e ganhe meses grátis para toda a vida! ✌🏻💯",
    text_header_publicidadPrueba1: "Tem",
    text_header_publicidadPrueba2: "dia do julgamento",
    text_header_publicidadPrueba3: "dias de ensaio ✌🏻💪🏻",
    text_header_publicidadPrueba4:
      "dias de assinatura. Se você tem pagamento automático não se preocupe, continue impulsionando suas vendas. 🚀",
    text_header_publicidadPrueba5:
      "dias de assinatura. Se você tem pagamento automático não se preocupe, continue impulsionando suas vendas. 🚀",
    text_header_btnrefer: "Consulte agora",
    text_header_btnrePubli: "Subscrever agora",
    text_header_howrefer: "Mais informações aqui",
    text_header_emprendedor: "Empresário",
    text_header_buscador: "Busca",
    text_header_inventario: "Selecione seu inventário",
    text_header_verTienda: "Ver loja",
    text_header_verPage: "Pré-visualização",
    text_header_cerrarSesion: "Logout",
    text_header_loNuevo: "O que há de novo",
    text_header_apagarTienda: "Fechamento de loja",
    //hoko
    text_hoko_btn: "Selecione",
    text_hoko_activar: "Ativar",
    text_hoko_quitar: "Remover",
    text_hoko_title: "Selecione seu tipo de inventário",
    text_hoko_title_text_1: "Inventário próprio",
    text_hoko_info1:
      "O inventário da loja online é responsável por estocar o estoque, fazer pedidos e gerenciar o envio dos pedidos.",
    text_hoko_title_text_2: "Inventário Hoko",
    text_hoko_info2:
      "A HOKO permite o acesso a centenas de fornecedores em todo o país com milhares de produtos e centenas de milhares de unidades prontas para venda e embarque, além disso, você pode ter estoques privados dos produtos mais vencedores ou de seus fornecedores exclusivos.",
    //Importar / Exportar
    text_paymentBlock_title_exportar: "Produtos de exportação",
    text_paymentBlock_descrip_exportar:
      "Baixe uma lista de seus próprios produtos em Komercia. Você pode baixá-la em formato .xls",
    text_client_descrip_exportar:
      "Faça o download de uma lista de seus próprios clientes na Komercia. Você pode baixá-la no formato .xls",
    text_paymentBlock_textbtn_exportar: "Exportar arquivo .xls",
    text_paymentBlock_title_importar: "Produtos de importação",
    text_paymentBlock_descrip_importar:
      "O formato permitido é .xlsx ou csv, que é uma extensão especial de planilhas eletrônicas como o Excel.",
    text_paymentBlock_textbtn_importar: "Importar arquivo .xls",
    text_paymentBlock_advertencia: "Advertência!",
    text_paymentBlock_text_advertencia:
      "Você excedeu o limite do produto de seu plano, você deve renovar sua adesão.",
    text_paymentBlock_renovar: "Renovar a filiação",
    text_paymentBlock_brear_text1: "Voltar > Importação / Exportação >",
    text_paymentBlock_brear_text2: "Arquivo de importação",
    text_paymentBlock_confirmar: "Confirme",
    text_statistics_tittle: "Estatísticas",
    // etiqueta
    text_etiquetas_title: "Atribuir etiqueta",
    text_etiquetas_newTag: "Nova etiqueta",
    text_etiquetas_assingedTag: "Etiquetas atribuídas",
    //helpDesk
    text_helpDesk_Aprende: "Saiba mais",
    text_helpDesk_diseño: "Desenho",
  },
};
