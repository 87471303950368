<template>
  <div v-if="animatedNumbers" class="grid gap-5 grid-cols-2 md:grid-cols-4">
    <div
      class="overflow-hidden rounded-lg px-4 py-5 shadow sm:p-6 flex flex-row bg-[#F2EFFF]"
    >
      <img
        loading="lazy"
        src="../../../assets/stats/devicePC.png"
        width="60"
        height="60"
        alt="IconMobile"
      />
      <div class="pl-5">
        <dt class="truncate text-sm font-medium text-gray-500">PC</dt>
        <el-tooltip
          effect="dark"
          :content="animatedNumbers[0] + ' ' + 'vistas'"
          placement="bottom-start"
        >
          <dd
            class="mt-1 text-3xl font-semibold tracking-tight text-gray-900 line-clamp-1"
          >
            {{ animatedNumbers[0] }}
            <span class="text-xs ml-1">Vistas</span>
          </dd>
        </el-tooltip>
      </div>
    </div>
    <div
      class="overflow-hidden rounded-lg px-4 py-5 shadow sm:p-6 flex flex-row bg-[#F2EFFF]"
    >
      <img
        loading="lazy"
        src="../../../assets/stats/deviceMobile.png"
        width="60"
        height="60"
        alt="IconMobile"
      />
      <div class="pl-5">
        <dt class="truncate text-sm font-medium text-gray-500">Mobile</dt>
        <el-tooltip
          effect="dark"
          :content="animatedNumbers[1] + ' ' + 'vistas'"
          placement="bottom-start"
        >
          <dd
            class="mt-1 text-3xl font-semibold tracking-tight text-gray-900 line-clamp-1"
          >
            {{ animatedNumbers[1] }}
            <span class="text-xs ml-1">Vistas</span>
          </dd>
        </el-tooltip>
      </div>
    </div>
    <div
      class="overflow-hidden rounded-lg px-4 py-5 shadow sm:p-6 flex flex-row bg-[#F2EFFF]"
    >
      <img
        loading="lazy"
        src="../../../assets/stats/deviceOther.png"
        width="60"
        height="60"
        alt="IconMobile"
      />
      <div class="pl-5">
        <dt class="truncate text-sm font-medium text-gray-500">Tablet</dt>
        <el-tooltip
          effect="dark"
          :content="animatedNumbers[2] + ' ' + 'vistas'"
          placement="bottom-start"
        >
          <dd
            class="mt-1 text-3xl font-semibold tracking-tight text-gray-900 line-clamp-1"
          >
            {{ animatedNumbers[2] }}
            <span class="text-xs ml-1">Vistas</span>
          </dd>
        </el-tooltip>
      </div>
    </div>
    <div
      class="overflow-hidden rounded-lg px-4 py-5 shadow sm:p-6 flex flex-row bg-[#F2EFFF]"
    >
      <img
        loading="lazy"
        src="https://cdn-icons-png.flaticon.com/512/9594/9594655.png"
        width="60"
        height="60"
        alt="IconMobile"
      />
      <div class="pl-5">
        <dt class="truncate text-sm font-medium text-gray-500">Otro</dt>
        <el-tooltip
          effect="dark"
          :content="animatedNumbers[3] + ' ' + 'vistas'"
          placement="bottom-start"
        >
          <dd
            class="mt-1 text-3xl font-semibold tracking-tight text-gray-900 line-clamp-1"
          >
            {{ animatedNumbers[3] }}
            <span class="text-xs ml-1">Vistas</span>
          </dd>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "StatsStore",
  data() {
    return {
      eventsCount: {},
      deviceCount: {},
      numbers: [100, 200, 300, 400], // Valores finales de tus números
      animatedNumbers: [0, 0, 0, 0],
    };
  },
  computed: {
    ...mapState(["storeData", "darkMode"]),
  },
  mounted() {
    this.getEventsCount();
    this.getDeviceCount();
  },
  methods: {
    async getEventsCount() {
      const { success, data } = await this.$store.dispatch(
        "analyticsStore/GET_EVENTS_COUNT"
      );
      if (success && data.success) {
        this.eventsCount = data.data;
      }
    },
    async getDeviceCount() {
      const { success, data } = await this.$store.dispatch(
        "analyticsStore/GET_DEVICE_COUNT"
      );
      if (success && data.success) {
        this.deviceCount = data.data;
        this.numbers = Object.values(this.deviceCount);
        if (this.numbers) {
          this.startCountAnimation();
        }
      }
    },
    startCountAnimation() {
      this.animatedNumbers = [0, 0, 0, 0];
      this.numbers.forEach((value, index) => {
        this.animateNumber(index, value);
      });
    },
    animateNumber(index, finalValue) {
      const duration = 1000;
      const interval = 10;
      const steps = duration / interval;
      const stepValue = finalValue / steps;

      let currentStep = 0;

      const intervalId = setInterval(() => {
        if (currentStep < steps) {
          this.$set(
            this.animatedNumbers,
            index,
            Math.round(stepValue * currentStep)
          );
          currentStep++;
        } else {
          this.$set(this.animatedNumbers, index, finalValue);
          clearInterval(intervalId);
        }
      }, interval);
    },
  },
};
</script>
<style scoped>
.wrapper-box-shadow {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
}
</style>
